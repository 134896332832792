import './styles.scss'
import React from 'react'

const WalletLogin = () => (
  <div className="box-wallet">
    <img src="/images/login/bg.png" alt="" />
    <div className="group-wallet-item">
      <img className="wallet-item" src="/images/Home/service/wallet-center.png" alt="" />
      <img className="wallet-item" src="/images/login/coin-1.png" alt="" />
      <img className="wallet-item" src="/images/login/coin-2.png" alt="" />
    </div>
  </div>
)

export default React.memo(WalletLogin)
