import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 18 15" {...props}>
    <path d="M16.2141 3.21429H2.8125C2.50172 3.21429 2.25 2.97455 2.25 2.67857C2.25 2.38259 2.50172 2.14286 2.8125 2.14286H16.3125C16.6233 2.14286 16.875 1.90312 16.875 1.60714C16.875 0.719531 16.1195 0 15.1875 0H2.25C1.00723 0 0 0.959263 0 2.14286V12.8571C0 14.0407 1.00723 15 2.25 15H16.2141C17.1991 15 18 14.2791 18 13.3929V4.82143C18 3.93516 17.1991 3.21429 16.2141 3.21429ZM14.625 10.1786C14.0038 10.1786 13.5 9.69877 13.5 9.10714C13.5 8.51551 14.0038 8.03571 14.625 8.03571C15.2462 8.03571 15.75 8.51551 15.75 9.10714C15.75 9.69877 15.2462 10.1786 14.625 10.1786Z" />
  </Svg>
)

export default Icon
