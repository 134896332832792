import React from 'react'
import './styles.scss'

const SearchInput = ({ value, onChange, props }) => (
  <div className="search-wrapper" {...props}>
    <input placeholder="Search" value={value} onChange={onChange} />
    <img src="/images/icons/search.svg" alt="" />
  </div>
)

export default SearchInput
