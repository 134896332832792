export const GET_CHAIN_LIST = 'GET_CHAIN_LIST'
export const GET_CHAIN_LIST_SUCCESS = 'GET_CHAIN_LIST_SUCCESS'

export const GET_CURRENCY = 'GET_CURRENCY'
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS'

export const GET_WALLET_ADDRESS = 'GET_WALLET_ADDRESS'
export const GET_WALLET_ADDRESS_SUCCESS = 'GET_WALLET_ADDRESS_SUCCESS'

export const CREATE_WALLET_ADDRESS = 'CREATE_WALLET_ADDRESS'

export const FETCH_WALLET_BALANCE = 'FETCH_WALLET_BALANCE'
export const FETCH_WALLET_BALANCE_SUCCESS = 'FETCH_WALLET_BALANCE_SUCCESS'

export const WITHDRAW = 'WITHDRAW'
export const VERIFY_WITHDRAW = 'VERIFY_WITHDRAW'
export const RESEND_VERIFY_WITHDRAW_CODE = 'RESEND_VERIFY_WITHDRAW_CODE'

export const GET_CURRENCY_ATTR = 'GET_CURRENCY_ATTR'
export const GET_CURRENCY_ATTR_SUCCESS = 'GET_CURRENCY_ATTR_SUCCESS'
