import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 22 19" {...props}>
    <path d="M10.8147 12.0629C10.8367 12.0956 10.8648 12.122 10.8969 12.1402C10.929 12.1584 10.9643 12.1678 11 12.1678C11.0357 12.1678 11.071 12.1584 11.1031 12.1402C11.1352 12.122 11.1633 12.0956 11.1853 12.0629L14.4794 7.22066C14.6 7.04296 14.4912 6.77983 14.2941 6.77983H12.1147V0.273315C12.1147 0.122955 12.0088 -6.67558e-05 11.8794 -6.67558e-05H10.1147C9.98529 -6.67558e-05 9.87941 0.122955 9.87941 0.273315V6.77641H7.70588C7.50882 6.77641 7.4 7.03954 7.52059 7.21724L10.8147 12.0629ZM21.7647 10.8669H20C19.8706 10.8669 19.7647 10.9899 19.7647 11.1403V16.4029H2.23529V11.1403C2.23529 10.9899 2.12941 10.8669 2 10.8669H0.235294C0.105882 10.8669 0 10.9899 0 11.1403V17.9065C0 18.5113 0.420588 19 0.941176 19H21.0588C21.5794 19 22 18.5113 22 17.9065V11.1403C22 10.9899 21.8941 10.8669 21.7647 10.8669Z" />
  </Svg>
)

export default Icon
