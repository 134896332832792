import React, { useCallback, useEffect, useState } from 'react'
import { Radio, List, Switch } from 'antd'
import SearchInput from 'widgets/SearchInput'
import CardStakingItem, { LIVE_STATUS } from '../CardStakingItem'
import './style.scss'
import ModalStaking from 'components/ModalStaking'
import { useSelector, useDispatch } from 'react-redux'
import { getPackageList } from 'state/stake/actions'
import { useParams } from 'react-router-dom'
import Nodata from 'components/Nodata'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getTransactionList } from 'state/transactions/actions'
import Loading from 'widgets/Loading/Loading'
import { removeEmpty } from 'helpers'
import { fetchWalletBalance } from 'state/wallets/actions'

const responsiveList = { gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 }

const StakeList = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const { packageList } = useSelector((state) => state.stake)

  const [modalStaking, setModalStaking] = useState({ toggle: false, dataModal: null })
  const [searchCode, setSearchCode] = useState()
  const [loading, setLoading] = useState(false)

  const handleStateNow = (item) => {
    setModalStaking({ toggle: true, dataModal: item })
  }

  const [txParamsStakingList, setTxParamsStakingList] = useState({
    page: 1,
    pageSize: 6,
    live_status: LIVE_STATUS.LIVE, // status (UPCOMING,LIVE,ENDED)
    currency: undefined,
  })
  useEffect(() => {
    setTxParamsStakingList((prev) => ({
      ...prev,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (txParamsStakingList.status_url) {
      dispatch(getTransactionList(removeEmpty(txParamsStakingList)))
    }
  }, [dispatch, txParamsStakingList])

  const handleSearchCode = (value) => {
    setSearchCode(value.toUpperCase())
  }

  useEffect(() => {
    setSearchCode(id || '')
  }, [id])

  useEffect(() => {
    if (txParamsStakingList) {
      dispatch(getPackageList(txParamsStakingList))
    }
  }, [dispatch, txParamsStakingList])

  const updateStakingList = useCallback(() => {
    dispatch(getTransactionList(removeEmpty(txParamsStakingList)))
  }, [dispatch, txParamsStakingList])

  const handleModeChange = (e) => {
    setTxParamsStakingList((prev) => ({
      ...prev,
      live_status: e.target.value,
    }))
  }

  const loadMoreData = () => {
    if (loading) {
      return
    }
    setTxParamsStakingList((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 6,
    }))
  }

  /* First init fetch data */
  useEffect(() => {
    loadMoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resultSearch = searchCode
    ? packageList?.rows?.filter((entry) =>
        Object.values(entry?.currency_stake).some((val) => typeof val === 'string' && val?.includes(searchCode)),
      )
    : packageList?.rows

  /**
   * Verify code success withdraw
   */
  const handleFinishStaking = useCallback(() => {
    dispatch(getPackageList(txParamsStakingList))
    fetchWalletBalance([{ code: modalStaking?.dataModal?.currency_stake?.code }])
    dispatch(getTransactionList(txParamsStakingList))
  }, [dispatch, modalStaking, txParamsStakingList])

  return (
    <div className="stake-list-container">
      <div className="stake-list-header">
        <div className="stake-list-header-filter">
          <div className="stake-list-header-filter-tab">
            <h3 className="title">Staking List</h3>

            <Radio.Group onChange={handleModeChange} value={txParamsStakingList.live_status}>
              <Radio.Button defaultActiveKey value={LIVE_STATUS.UPCOMING}>
                Upcoming
              </Radio.Button>
              <Radio.Button defaultActiveKey value={LIVE_STATUS.LIVE}>
                Live
              </Radio.Button>
              <Radio.Button value={LIVE_STATUS.ENDED}>End</Radio.Button>
            </Radio.Group>
          </div>

          <div className="stake-list-header-filter-tab-right">
            <div className="stake-list-header-filter-search">
              <SearchInput
                className="stake-list-search-input"
                value={searchCode}
                onChange={(value) => handleSearchCode(value.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="stake-list-content" id="scrollableDiv">
        {resultSearch?.length > 0 ? (
          <InfiniteScroll
            className="infinite-scroll"
            style={{ width: '100%', textAlign: 'center', margin: '0 auto' }}
            dataLength={packageList?.rows?.length || 0}
            next={loadMoreData}
            hasMore={packageList?.rows?.length < packageList?.total}
            loader={
              <List
                style={{ width: '100%', textAlign: 'center', margin: '0 auto' }}
                dataSource={[{}]}
                grid={responsiveList}
                renderItem={(_, index) => (
                  <List.Item key={`list-${index}`}>
                    <div className="loading-list-stake">
                      <div className="loading-list-stake-icon">
                        <span style={{ marginRight: '4px' }}>Loading...</span>
                        <Loading />
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={resultSearch || []}
              grid={responsiveList}
              renderItem={(item) => (
                <List.Item key={`list-${item._id}`}>
                  <CardStakingItem
                    item={item}
                    txParamsStakingList={txParamsStakingList}
                    onStakeNow={handleStateNow}
                    updateStakingList={updateStakingList}
                  />
                </List.Item>
              )}
              locale={{
                emptyText: packageList?.total === 0 ? <div>Empty</div> : <></>,
              }}
            />
          </InfiniteScroll>
        ) : (
          <div className="nodata-listStaking">
            <Nodata />
          </div>
        )}
      </div>
      <ModalStaking
        visible={modalStaking.toggle}
        dataModal={modalStaking.dataModal}
        onCancel={() => setModalStaking((prev) => ({ ...prev, toggle: false }))}
        onFinish={handleFinishStaking}
      />
    </div>
  )
}

export default StakeList
