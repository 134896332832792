import React from 'react'
import './styles.scss'
import AuthenCode from 'modules/auth/components/VerifyResetPasswordCode'
import ModalCustom from 'components/BaseModal'

const ModalVerificationEmailCode = ({ visible, email, close, timeResend, setTimeResend }) => (
  <ModalCustom
    className="modal-change-password"
    title=""
    centered
    visible={Boolean(visible)}
    width={720}
    onCancel={() => close(false)}
  >
    <AuthenCode
      title="Change Password"
      style={{ width: '100%', maxWidth: '100%' }}
      email={email}
      timeResend={timeResend}
      setTimeResend={setTimeResend}
    />
    <div className="modal-scan-action-bg" />
  </ModalCustom>
)

export default ModalVerificationEmailCode
