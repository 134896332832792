import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

const StakeCardOverview = ({ content, title, url, viewAll }) => {
  const TagName = url ? Link : 'div'
  const TagProps = url ? { to: url } : {}
  return (
    <div className="stake-card">
      <TagName className="stake-card-content" {...TagProps}>
        <div className="stake-card-title">{title}</div>
        <div className="stake-card-balance">{content}</div>
        {viewAll && (
          <div className="stake-card-Link">
            <span>View all</span>
            <img src="/images/icons/right-arrow.png" alt="" />
          </div>
        )}
      </TagName>
    </div>
  )
}
export default StakeCardOverview
