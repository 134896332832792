import { combineReducers } from 'redux'
import App from './app/reducer'
import Auth from './auth/reducer'
import User from './user/reducer'
import Home from './home/reducer'
import Wallets from './wallets/reducer'
import Transactions from './transactions/reducer'
import File from './file/reducer'
import Exchange from './exchange/reducer'
import Stake from './stake/reducer'

export default combineReducers({
  app: App,
  auth: Auth,
  home: Home,
  user: User,
  wallets: Wallets,
  transactions: Transactions,
  file: File,
  exchange: Exchange,
  stake: Stake,
})
