import * as types from './constants'

export const getOrderList = (payload) => ({
  type: types.GET_ORDER_LIST,
  payload,
})
export const getOrderListSuccess = (payload) => ({
  type: types.GET_ORDER_LIST_SUCCESS,
  payload,
})

export const getPackageList = (payload) => ({
  type: types.GET_PACKAGE_LIST,
  payload,
})
export const getPackageListSuccess = (payload) => ({
  type: types.GET_PACKAGE_LIST_SUCCESS,
  payload,
})

export const orderStake = (payload, cbs, cbe) => ({
  type: types.ORDER_STAKE,
  payload,
  cbs,
  cbe,
})
export const getOrderDetail = (payload, cbs, cbe) => ({
  type: types.GET_ORDER_DETAIL,
  payload,
  cbs,
  cbe,
})
export const getOrderDetailSuccess = (payload) => ({
  type: types.GET_ORDER_DETAIL_SUCCESS,
  payload,
})

export const orderStakeReport = (payload) => ({
  type: types.GET_ORDER_STAKE_REPORT,
  payload,
})
export const orderStakeReportSuccess = (payload) => ({
  type: types.GET_ORDER_STAKE_REPORT_SUCCESS,
  payload,
})

export const orderStakeClaimReward = (payload) => ({
  type: types.GET_ORDER_STAKE_CLAIM_REWARD,
  payload,
})

export const requestUnstake = (payload, cbs, cbe) => ({
  type: types.REQUEST_UNSTAKE,
  payload,
  cbs,
  cbe,
})
