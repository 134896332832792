import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import { Spin } from 'antd'
import './styles.scss'
import { DECIMALS } from 'constants/index'
import { roundNumber, formatDate } from 'helpers'
import { getOrderDetail, requestUnstake } from 'state/stake/actions'
import ModalCustom from 'components/BaseModal'
import ButtonPrimary from 'widgets/ButtonPrimary'

const ModalViewDetails = ({ visible, dataModal, onCancel, onUnstakeSuccess }) => {
  const dispatch = useDispatch()
  const { currency } = useSelector((state) => state.wallets)
  const fCurrency = currency?.find((curr) => curr._id === dataModal?.currency_stake_id)

  const [orderDetail, setOrderDetail] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (dataModal) {
      dispatch(
        getOrderDetail(
          {
            order_id: dataModal._id,
          },
          (data) => setOrderDetail(data),
        ),
      )
    }
  }, [dataModal, dispatch])

  const handleUnstake = () => {
    setLoading(true)
    dispatch(
      // orderStakeClaimReward(
      requestUnstake(
        {
          order_id: orderDetail._id,
        },
        () => {
          onCancel({ toggle: false })
          setLoading(false)
          onUnstakeSuccess()
        },
        () => {
          setLoading(false)
        },
      ),
    )
  }

  const receivedUnstake = useMemo(() => {
    if (!orderDetail) return 0
    return orderDetail.status === 'HOLDING' ? orderDetail.amount_stake - orderDetail.fee_unstake : orderDetail.amount_stake
  }, [orderDetail])

  return (
    <ModalCustom
      className="modal-staking-details"
      title={
        <div className="taking-detail-heading">
          <span>Stake Detail</span>
          <span data-status={orderDetail?.status}>{orderDetail?.status}</span>
        </div>
      }
      centered
      visible={Boolean(visible)}
      width={window.innerWidth <= 768 ? '100vw' : 580}
      height={window.innerWidth <= 768 ? '100%' : 550}
      onCancel={() => onCancel({ toggle: false })}
    >
      {dataModal && orderDetail ? (
        <>
          <div className="modal-scan-action-bg" />

          <ul className="modal-staking-details-steps">
            <li>
              <p>Stake Date</p>
              <p>{formatDate(orderDetail.subscription_date * 1000, 'YYYY/MM/DD')}</p>
            </li>
            <li>
              <p>Value Date</p>
              <p>{formatDate(orderDetail.subscription_date * 1000, 'YYYY/MM/DD')}</p>
            </li>
            <li>
              <p>End Date</p>
              <p>{formatDate(orderDetail.redemption_date * 1000, 'YYYY/MM/DD')}</p>
            </li>
          </ul>

          <div className="modal-staking-details-info">
            <div className="modal-staking-details-info-item">
              <p>{fCurrency ? ` ${fCurrency?.code}` : ''} Staked : </p>
              <p>
                <CurrencyFormat
                  value={roundNumber(orderDetail.amount_stake, { scale: 6, decimals: DECIMALS })}
                  displayType="text"
                  thousandSeparator
                  // suffix={fCurrency ? ` ${fCurrency?.code}` : ''}
                  renderText={(v) => v}
                />
              </p>
            </div>

            <div className="modal-staking-details-info-item">
              <p>{fCurrency ? ` ${fCurrency?.code}` : ''} Received : </p>
              <p>
                <CurrencyFormat
                  value={roundNumber(receivedUnstake || 0, {
                    scale: 6,
                    decimals: DECIMALS,
                  })}
                  displayType="text"
                  thousandSeparator
                  // suffix={fCurrency ? ` ${fCurrency?.code}` : ''}
                  renderText={(v) => v}
                />
              </p>
            </div>

            {orderDetail?.rewards && (
              <>
                {orderDetail?.rewards?.map((item) => (
                  <div className="modal-staking-details-info-item" key={item._id}>
                    <p style={{ display: 'flex', alignItems: 'center' }}>
                      {/* <img src={item.currency_reward.icon} alt="" style={{ width: '20px', height: '20px', marginRight: '5px' }} /> */}
                      {item.currency_reward.code} Earned :{' '}
                    </p>
                    <p>
                      <CurrencyFormat
                        value={roundNumber(item.earned, { scale: 6, decimals: 18 })}
                        displayType="text"
                        thousandSeparator
                        renderText={(v) => v}
                      />
                    </p>
                  </div>
                ))}
              </>
            )}

            {/* {orderDetail?.rewards && (
              <>
                {orderDetail?.rewards?.map((item) => {
                  let reward = 0
                  const currentTime = new Date().getTime()
                  const rateRewardToken = item.rate_reward_token
                  const usdRate = item.currency_reward.usd_rate || 0
                  const startDate = orderDetail.subscription_date * 1000
                  const amountStake = orderDetail.amount_stake / 1e18

                  // term lock => 30 days or more
                  const termDate =
                    (orderDetail.redemption_date * 1000 - orderDetail.subscription_date * 1000) / 1000 / 60 / 60 / 24

                  // calculate lock time
                  let timeWasLocked = (currentTime - startDate) / 1000 / 60 / 60 / 24
                  timeWasLocked = timeWasLocked > termDate ? termDate : timeWasLocked

                  // percent day
                  const percentPerDay = item.apr_reward / 365

                  reward = (amountStake * percentPerDay * timeWasLocked) / 100

                  if (rateRewardToken) {
                    reward /= rateRewardToken
                  }
                  if (usdRate && item.currency_reward.code !== 'OPV') {
                    reward = rateRewardToken * usdRate
                  }

                  return (
                    <div className="modal-staking-details-info-item" key={item._id}>
                      <p style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={item.currency_reward.icon}
                          alt=""
                          style={{ width: '20px', height: '20px', marginRight: '5px' }}
                        />
                        {item.currency_reward.code} Reward :{' '}
                      </p>
                      <p>
                        <CurrencyFormat
                          value={roundNumber(reward, { scale: 6 })}
                          displayType="text"
                          thousandSeparator
                          renderText={(v) => v}
                        />
                      </p>
                    </div>
                  )
                })}
              </>
            )} */}
          </div>

          <div className="modal-staking-details-btn">
            <ButtonPrimary onClick={handleUnstake} loading={loading}>
              Unstake
            </ButtonPrimary>
          </div>
        </>
      ) : (
        <div className="loading">
          <Spin />
        </div>
      )}
    </ModalCustom>
  )
}

export default ModalViewDetails
