import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import AuthCard from '../AuthCard'
import { resendAuthenLoginAccountCode, verifyEmail } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'

const VerifyEmailCode = ({ type, formValue, initialField, token, onFinish, onBack, children, ...props }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(50)

  const onSubmit = () => {
    if (codeValue.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters from email of you.`)
    } else {
      setErrorMess('')
      setLoading(true)
      switch (type) {
        case 'login':
          dispatch(
            verifyEmail(
              {
                token,
                code: codeValue,
                os: window.navigator.platform,
                device_id: window.navigator.appVersion,
              },
              (receipt) => {
                onFinish({ code: codeValue, type: 'is_email', ...receipt })
                setLoading(false)
              },
              () => {
                setLoading(false)
              },
            ),
          )
          break
        default:
          break
      }
    }
  }

  const onChangeCode = (value) => {
    if (value.length >= initialField) {
      setErrorMess('')
    }
    setCodeValue(value)
  }

  const resendCode = () => {
    switch (type) {
      case 'login':
        dispatch(
          resendAuthenLoginAccountCode(
            {
              token,
            },
            () => {
              setTimeCountDown(50)
            },
          ),
        )
        break
      default:
        break
    }
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  return (
    <AuthCard className="verify-code fadeIn" {...props}>
      <h2 className="verify-title">Verification code</h2>
      <div className="verify-input">
        <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
      </div>
      <div className="verify-resend">
        <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>Resend in {timeCountDown}</div>
        {timeCountDown <= 0 && (
          <a onClick={resendCode} role="presentation">
            Resend
          </a>
        )}
      </div>
      <div className="verify-error">{errorMess}</div>
      <div className="verify-action">
        <ButtonPrimary loading={loading} onClick={onSubmit}>
          Done
        </ButtonPrimary>
        <ButtonSecondary onClick={onBack}>Back</ButtonSecondary>
      </div>
    </AuthCard>
  )
}

VerifyEmailCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
  onBack: () => null,
}

export default React.memo(VerifyEmailCode)
