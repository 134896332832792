import * as types from './constants'

// swap only
export const selectCurrency = (payload) => ({
  type: types.SELECT_CURRENCY,
  payload,
})
export const switchCurrencies = (payload, callback) => ({
  type: types.SWITCH_CURRENCIES,
  payload,
  callback,
})
export const typeInput = (payload) => ({
  type: types.TYPE_INPUT,
  payload,
})

// saga
export const postExchange = (payload, cbs, cbe) => ({
  type: types.POST_EXCHANGE,
  payload,
  cbs,
  cbe,
})
