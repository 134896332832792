import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './styles.scss'
import TitleSection from '../titleSection'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { ArrowNext } from 'widgets/Svg'

const Service = () => {
  const { userInfo } = useSelector((state) => state.user)
  return (
    <div className="warp-service">
      <TitleSection
        title="What you can do with Zodi Pay ?"
        description="Many utilities and attractive services are always ready to serve you"
      />

      <div className="warp-service-content">
        <div className="warp-service-content-background">
          <div className="service-center">
            <img src="/images/Home/service/wallet-center.png" alt="" />
            <Link to={userInfo ? '/wallet' : '/login'}>
              <ButtonPrimary style={{ maxWidth: '150px' }} arrow={<ArrowNext width="24px" style={{ marginLeft: '10px' }} />}>
                TRY IT NOW
              </ButtonPrimary>
            </Link>
          </div>

          <img className="background" src="/images/Home/service/backgroung.png" alt="" />
          <div className="halo">
            <img src="/images/Home/service/halo.png" alt="" />
            <img src="/images/Home/service/halo.png" alt="" />
          </div>
          <div className="icon-circle">
            <img src="/images/Home/service/circle.png" alt="" />
            <img src="/images/Home/service/circle.png" alt="" />
            <img src="/images/Home/service/circle.png" alt="" />
            <img src="/images/Home/service/circle.png" alt="" />
            <img src="/images/Home/service/circle.png" alt="" />
          </div>
        </div>

        <div className="service-center">
          <img src="/images/Home/service/wallet-center.png" alt="" />
          <Link to={userInfo ? '/wallet' : '/login'}>
            <ButtonPrimary
              style={{ maxWidth: '172px', display: 'inherit' }}
              arrow={<ArrowNext width="20px" style={{ marginLeft: '10px' }} />}
            >
              TRY IT NOW
            </ButtonPrimary>
          </Link>
        </div>

        <div className="service-group">
          <div className="service-group-item">
            <div className="service-item">
              <div className="service-item-content">
                <div>
                  <img src="/images/Home/service/staking.png" alt="" />
                </div>
                <div>
                  <p>Staking</p>
                  <p>Be a part of the project by staking your tokens into the system and get the extremely hot APY</p>
                </div>
              </div>
            </div>
            <div className="service-item">
              <div className="service-item-content">
                <div>
                  <img src="/images/Home/service/service.png" alt="" />
                </div>
                <div>
                  <p>Payment</p>
                  <p>Facilitate your payments with third-party services</p>
                </div>
              </div>
            </div>
          </div>

          <div className="service-group-item">
            <div className="service-item">
              <div className="service-item-content">
                <div>
                  <img src="/images/Home/service/wallet.png" alt="" />
                </div>
                <div>
                  <p>Wallet</p>
                  <p>Safely storing your crypto assets in the all-in-one wallet</p>
                </div>
              </div>
            </div>
            <div className="service-item">
              <div className="service-item-content">
                <div>
                  <img src="/images/Home/service/exchange.png" alt="" />
                </div>
                <div>
                  <p>Exchange</p>
                  <p>Effortlessly moving your tokens among various blockchains with low fees and high speed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service
