import React from 'react'
import { Link } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import TableWrapper from 'components/TableWrapper'
import { roundNumber } from 'helpers'
import { DepositIcon, WithdrawIcon } from 'widgets/Svg'
import Loading from 'widgets/Loading/Loading'
import { DECIMALS } from 'constants/index'

const header = [
  {
    title: 'Coin',
    dataIndex: 'title',
    width: '30%',
    render: (text, record) => (
      <div className="coin-data">
        <img src={record.icon} alt="" />
        <div className="coin-data-label">
          <div>{text}</div>
          <div>{record.type}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'balance',
    width: '20%',
    render: (text) =>
      text !== undefined ? (
        <CurrencyFormat
          value={roundNumber(text, { scale: 6, decimals: 18 })}
          displayType="text"
          thousandSeparator
          // suffix={` ${record.title}`}
        />
      ) : (
        <Loading />
      ),
  },
  {
    title: 'USD value',
    dataIndex: 'usd_rate',
    width: '20%',
    mobileHidden: true,
    render: (text, record) =>
      record.balance !== undefined && record.balance !== null ? (
        <>
          <CurrencyFormat
            value={roundNumber(text * record.balance, { decimals: 18 })}
            displayType="text"
            thousandSeparator
            suffix="$"
          />
        </>
      ) : (
        <Loading />
      ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (_, record) => (
      <div className="actions">
        <Link to={`/deposit/${record.code}`}>
          <button type="button">
            <DepositIcon />
            Deposit
          </button>
        </Link>
        <Link to={`/withdraw/${record.code}`}>
          <button type="button">
            <WithdrawIcon />
            Withdraw
          </button>
        </Link>
      </div>
    ),
  },
]

const WalletsList = ({ currency }) => (
  <div className="wallet-list-container">
    {window.innerWidth >= 768 ? (
      <TableWrapper
        className="table-wallet-list-desktop"
        columns={header}
        dataSource={currency || []}
        rowKey={(record) => record._id}
        pagination={false}
      />
    ) : (
      <div className="wallet-list-mobile">
        {currency?.map((item, index) => (
          <div className="wallet-item" key={`wallet-item-${index}`}>
            <div className="coin-data">
              <img src={item.icon} alt="" />
              <div className="coin-data-label">
                <div>{item.title}</div>
                <div>{item.type}</div>
              </div>
            </div>
            <div className="wallet-item-right">
              <div className="balance">
                {item.balance !== undefined ? (
                  <>
                    <CurrencyFormat
                      value={roundNumber(item.balance / 10 ** DECIMALS, 6)}
                      displayType="text"
                      thousandSeparator
                      suffix={` ${item.title}`}
                    />
                    <span>
                      (~$
                      <CurrencyFormat
                        value={roundNumber(item.usd_rate * (item.balance / 10 ** DECIMALS), 6)}
                        displayType="text"
                        thousandSeparator
                      />
                      )
                    </span>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
              <div className="actions">
                <Link to={`/deposit/${item.code}`}>
                  <button type="button">
                    <DepositIcon />
                    Deposit
                  </button>
                </Link>
                <Link to={`/withdraw/${item.code}`}>
                  <button type="button">
                    <WithdrawIcon />
                    Withdraw
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
    {/* <ModalCreateWallet visible={modalCreateWallet.toggle} onCancel={setModalCreateWallet} /> */}
  </div>
)

export default WalletsList
