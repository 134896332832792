import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import './styles.scss'
import { logout } from 'state/auth/actions'
import { linksPrivate } from 'components/Menu/config'

const ProfileAccount = ({ hideName, className }) => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.user)

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <Dropdown
      overlay={
        <div className="dropdown-logout" role="presentation">
          {linksPrivate.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
            const Icon = item?.icon
            return (
              <Tag {...propsLink} key={`dropdow-menu-${index}`}>
                {item.icon && <Icon />}
                <span>{item.label}</span>
              </Tag>
            )
          })}
          <a role="presentation" onClick={handleLogout}>
            <LogoutOutlined /> <span>Logout</span>
          </a>
        </div>
      }
      trigger={['click']}
    >
      <div className={`${className || ''} profile-account`}>
        <div className="background-cover">
          <span>
            <img
              src={userInfo?.profile?.avatar ? userInfo?.profile?.avatar : '/images/user-default.png'}
              alt=""
              title=""
              onError={(e) => {
                e.target.onerror = null
                e.target.src = '/images/user-default.png'
              }}
            />
          </span>
        </div>
        {!hideName && userInfo?.profile && <span>{`${userInfo?.profile?.fistname} ${userInfo?.profile?.lastname}`}</span>}
      </div>
    </Dropdown>
  )
}

export default ProfileAccount
