import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 20 21" {...props}>
    <path d="M10 20.5C4.477 20.5 0 16.023 0 10.5C0 4.977 4.477 0.5 10 0.5C15.523 0.5 20 4.977 20 10.5C20 16.023 15.523 20.5 10 20.5ZM10 7.5H6V9.5H15L10 4.5V7.5ZM5 11.5L10 16.5V13.5H14V11.5H5Z" />
  </Svg>
)

export default Icon
