import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import HeaderMobile from 'components/Menu/HeaderMobile'
import { linksPublic } from 'components/Menu/config'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'

import './style.scss'
import ProfileAccount from 'components/ProfileAccount'

const PublicLayout = ({ isLogin, children }) => {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
    setShowMenu((prev) => !prev)
  }

  return (
    <div className="public-layout-container">
      <header className="desktop-header">
        <div className="header-left">
          <Link to="/">
            <div className="header-logo">
              <img src="/images/logo-text.png" title="Zodi Pay" alt="Zodi Pay" />
            </div>
          </Link>
        </div>
        <div className="header-right">
          {isLogin ? (
            <ProfileAccount />
          ) : (
            <>
              <Link to="/register" onClick={toggleMenu} style={{ marginRight: '15px' }}>
                <ButtonSecondary
                  style={{
                    minWidth: '122px',
                    color: location.pathname === '/register' ? '#000' : '#fff',
                    background: location.pathname === '/register' ? '#fff' : 'transparent',
                  }}
                >
                  Sign up
                </ButtonSecondary>
              </Link>
              <Link to="/login" onClick={toggleMenu}>
                <ButtonPrimary
                  style={{
                    minWidth: '122px',
                    color: location.pathname === '/login' ? '#000' : '#fff',
                    background: location.pathname === '/login' ? '#fff' : 'transparent',
                  }}
                >
                  Sign in
                </ButtonPrimary>
              </Link>
            </>
          )}
        </div>
      </header>
      <HeaderMobile links={linksPublic} visible={showMenu} isLogin={isLogin} location={location} toggleMenu={toggleMenu} />
      {children}
    </div>
  )
}

export default PublicLayout
