import './styles.scss'
import React from 'react'
import SelectWithImage from 'widgets/SelectWithImage'

const ControlInputSelect = ({ label, dataSelect, defaultValue, ...props }) => (
  <div className="input-select-item">
    {label ? <p>{label}</p> : ''}
    <SelectWithImage
      defaultValue={defaultValue}
      dataSelect={dataSelect}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...props}
    />
  </div>
)

export default React.memo(ControlInputSelect)
