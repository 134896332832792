import { removeEmpty } from 'helpers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTransactionList } from './actions'

export const useTransaction = () => {
  const transactions = useSelector((state) => state.transactions.transactionsList)
  return transactions
}

export const useFetchTransactions = (params) => {
  const dispatch = useDispatch()

  const [paramsTxs, setParamsTxs] = useState({
    page: 1,
    pageSize: 10,
    currency: '',
    type: '',
    is_exchange: '',
    ...(params && params),
  })

  useEffect(() => {
    dispatch(getTransactionList(removeEmpty(paramsTxs)))
  }, [dispatch, paramsTxs])

  const transactions = useTransaction()
  return { transactions, paramsTxs, setParamsTxs }
}
