import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import './styles.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import InputCode from 'components/InputCode'
import ModalCustom from 'components/BaseModal'
import { verifyWithdraw } from 'state/wallets/actions'
import { toast } from 'react-toastify'
import ResendCode from 'components/ResendCode'

const initialField = 6

const ModalVerificationCode = ({ visible, dataModal, onFinish, onCancel }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [emailCode, setEmailCode] = useState('')
  const [gg2faCode, setGg2faCode] = useState('')
  const [errorMess, setErrorMess] = useState({
    email: '',
    gg2fa: '',
  })

  const handleConfirm = () => {
    if (!dataModal) return toast.error('An occurred error, Please try again late.')

    if (emailCode.length < 6 || gg2faCode.length < 6) {
      setErrorMess((prev) => ({
        ...prev,
        email: emailCode.length < 6 ? `Please enter ${initialField} characters from email of you.` : '',
        gg2fa:
          gg2faCode.length < 6 ? `Please enter ${initialField} characters from Google Authenticator app on mobile of you.` : '',
      }))
      return
    }

    setErrorMess(false)
    setLoading(true)
    dispatch(
      verifyWithdraw(
        {
          TransactionCode: dataModal._id,
          email_code: emailCode,
          auth_code: gg2faCode,
        },
        (receipt) => {
          onFinish(receipt)
          setLoading(false)
          onCancel({ toggle: false, dataModal: null })
          setEmailCode('')
          setGg2faCode('')
        },
        () => {
          setLoading(false)
        },
      ),
    )
  }

  return (
    <ModalCustom
      className="modal-verification-code"
      title="Confirm Withdraw"
      centered
      visible={Boolean(visible)}
      width={720}
      onCancel={() => {
        onCancel({ toggle: false, dataModal: null })
        setErrorMess('')
        setEmailCode('')
        setGg2faCode('')
      }}
    >
      {/* <p className="title-modal">{step === 1 ? 'Verification Code' : 'Google Authenticator'}</p> */}

      {/* <div className="list-steps-verify">
        <ul>
          <li className={step === 1 ? 'current' : 'active'}>
            <p>Verify code</p>
          </li>
          <li className={step === 2 ? 'current' : ''}>
            <p>GG2FA</p>
          </li>
        </ul>
      </div> */}

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <p className="modal-verification-code-note">Input your Email OTP</p>
          <InputCode
            initialField={initialField}
            hasErrored={!!errorMess.email}
            value={emailCode}
            onChange={(v) => {
              setEmailCode(v)
              if (v.length >= 6) {
                setErrorMess((prev) => ({
                  ...prev,
                  email: '',
                }))
              }
            }}
          />
          <ResendCode data={dataModal} />
        </Col>
        <Col span={24}>
          <p className="modal-verification-code-note">Input your Google Authenticator</p>
          <InputCode
            initialField={initialField}
            hasErrored={!!errorMess.gg2fa}
            value={gg2faCode}
            onChange={(v) => {
              setGg2faCode(v)
              if (v.length >= 6) {
                setErrorMess((prev) => ({
                  ...prev,
                  gg2fa: '',
                }))
              }
            }}
          />
        </Col>
      </Row>

      <div className="modal-scan-action">
        <ButtonSecondary
          style={{ maxWidth: '150px', marginRight: '24px' }}
          onClick={() => {
            onCancel({ toggle: false, dataModal: null })
            setEmailCode('')
            setGg2faCode('')
          }}
        >
          CLOSE
        </ButtonSecondary>
        <ButtonPrimary style={{ maxWidth: '150px' }} loading={loading} onClick={handleConfirm}>
          CONFIRM
        </ButtonPrimary>
      </div>

      <div className="modal-scan-action-bg" />
    </ModalCustom>
  )
}

export default ModalVerificationCode
