import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { get } from 'lodash'
import { Form, Upload } from 'antd'
import './styles.scss'
import BackLink from 'components/BackLink'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ControlInputSelect from 'components/ControlInputSelect'
import ControlInputText from 'components/ControlInputText'
import { getListCountry } from 'state/app/actions'
import { dummyRequest, getBase64 } from 'helpers'
import { getProfile, updateKyc } from 'state/user/actions'
import { uploadFile } from 'state/file/actions'
import AntDatePicker from 'widgets/AntDatePicker'

const { Dragger } = Upload

export const ID_TYPE = [
  {
    label: 'Passport',
    value: 'PASSPORT',
  },
  {
    label: 'ID Card',
    value: 'INDENTITY_CARD',
  },
]

const DOCUMENT_IMAGE_TYPE = {
  FRONT: 'FRONT',
  BACK: 'BACK',
  SELFIE: 'SELFIE',
}

const PrimaryKYC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form] = Form.useForm()

  const listCountry = useSelector((state) => state.app.listCountry)

  const [idType, setIdType] = useState()
  const [loading, setLoading] = useState(false)
  const [frontImageData, setFrontImageData] = useState(null)
  const [backImageData, setBackImageData] = useState(null)
  const [selfieImageData, setSelfieImageData] = useState(null)
  const [frontImageError, setFrontImageError] = useState('')
  const [backImageError, setBackImageError] = useState(null)
  const [selfieImageError, setSelfieImageError] = useState(null)

  const handleOnChangeImage = (e, type) => {
    if (e.file.status === 'uploading') {
      return
    }
    if (e.file.status === 'done') {
      getBase64(e.file.originFileObj).then(() => {
        switch (type) {
          case DOCUMENT_IMAGE_TYPE.FRONT: {
            setFrontImageError('')

            dispatch(
              uploadFile(
                {
                  key: 1,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setFrontImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            break
          }
          case DOCUMENT_IMAGE_TYPE.BACK: {
            dispatch(
              uploadFile(
                {
                  key: 2,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setBackImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            setBackImageError('')
            break
          }
          case DOCUMENT_IMAGE_TYPE.SELFIE: {
            dispatch(
              uploadFile(
                {
                  key: 3,
                  image: get(e, 'file.originFileObj', null),
                },
                (receipt) => {
                  setSelfieImageData({
                    ...receipt,
                    image: receipt.full_link,
                  })
                },
              ),
            )
            setSelfieImageError('')
            break
          }
          default:
            break
        }
      })
    }
  }

  const handleSubmitForm = () => {
    try {
      if (!frontImageData) setFrontImageError('Error')
      if (!backImageData) setBackImageError('Error')
      if (!selfieImageData) setSelfieImageError('Error')
      form.validateFields().then((values) => {
        setLoading(true)
        const country = JSON.parse(values.country_code)
        const type = JSON.parse(values.type)
        const payload = {
          ...values,
          image_front: frontImageData._id,
          image_back: backImageData._id,
          image_selfie: selfieImageData._id,
          country_code: country.value,
          type: type.value,
          birthday: moment(values?.birthday?._d.getTime()).format('YYYY-MM-DD'),
        }
        dispatch(
          updateKyc(
            payload,
            () => {
              setLoading(false)
              dispatch(getProfile())
              history.push('/security')
            },
            () => {
              setLoading(false)
            },
          ),
        )
      })
    } catch (error) {
      setLoading(false)
    }
  }

  const countryOptions = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => ({
        label: item.title,
        value: item.code,
        // image: '/images/flag/vietnam.png',
        ...item,
      }))
    }
    return []
  }, [listCountry])

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  return (
    <div className="warp-page-primary-kyc">
      <div className="page-primary-kyc-content">
        <BackLink label="Security" to="/security" />

        <Form form={form}>
          <div className="primary-kyc-content-form">
            <div className="box-input-select">
              <Form.Item
                name="country_code"
                rules={[
                  {
                    required: true,
                    message: 'Please Select nationality',
                  },
                ]}
              >
                <ControlInputSelect label="Nationality" placeholder="Select nationality" dataSelect={countryOptions} />
              </Form.Item>
              <Form.Item name="type" rules={[{ required: true, message: 'Required' }]}>
                <ControlInputSelect
                  label="ID Type"
                  placeholder="Select ID type"
                  dataSelect={ID_TYPE}
                  onChange={(v) => setIdType(JSON.parse(v).value)}
                />
              </Form.Item>
            </div>

            <div className="box-input-text">
              <Form.Item name="number" rules={[{ required: true, message: 'Required' }]}>
                <ControlInputText label="ID Number" placeholder="Please enter your ID number" />
              </Form.Item>

              <Form.Item
                name="fistname"
                rules={[
                  {
                    required: true,
                    message: 'Please enter first name',
                  },
                ]}
              >
                <ControlInputText label="First Name" placeholder="Please enter your First Name" />
              </Form.Item>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: 'Please enter last name',
                  },
                ]}
              >
                <ControlInputText label="Last Name" placeholder="Please enter your Last Name" />
              </Form.Item>
              <Form.Item
                name="birthday"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Date of birth',
                  },
                ]}
              >
                <AntDatePicker format="YYYY-MM-DD" label="Date of birth" placeholder="Please enter your Date of birth" />
              </Form.Item>
            </div>

            <div className="box-input-file">
              {idType === ID_TYPE[0].value ? (
                <div className="input-file-item">
                  <p className="input-file-item-title">Passport Photo</p>
                  <p className="input-file-item-description">
                    Please make sure the content of the photo is complete and clearly visible <br />
                    Only supports JPG, JPEF,PNG, image formats <br />
                    Image size cannot exceed 5MB
                  </p>
                  <Dragger
                    customRequest={dummyRequest}
                    showUploadList={false}
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.FRONT)
                    }}
                    style={{ border: frontImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                  >
                    {frontImageData ? (
                      <img src={frontImageData.full_link} alt="" />
                    ) : (
                      <>
                        <p className="top-sub">ID photo Front</p>
                        <p className="ant-upload-drag-icon">
                          <img src="/images/upload-file.png" alt="" />
                        </p>
                        <p className="ant-upload-text">You can click me to upload files!</p>
                      </>
                    )}
                  </Dragger>
                </div>
              ) : (
                <div className="input-file-item">
                  <p className="input-file-item-title">ID Photo</p>
                  <p className="input-file-item-description">
                    Please make sure the content of the photo is complete and clearly visible <br />
                    Only supports JPG, JPEF,PNG, image formats <br />
                    Image size cannot exceed 5MB
                  </p>
                  <Dragger
                    customRequest={dummyRequest}
                    showUploadList={false}
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.FRONT)
                    }}
                    style={{ marginBottom: '32px', border: frontImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                  >
                    {frontImageData ? (
                      <img src={frontImageData.full_link} alt="" />
                    ) : (
                      <>
                        <p className="top-sub">ID photo Front</p>
                        <p className="ant-upload-drag-icon">
                          <img src="/images/upload-file.png" alt="" />
                        </p>
                        <p className="ant-upload-text">You can click me to upload files!</p>
                      </>
                    )}
                  </Dragger>
                </div>
              )}

              <div className="input-file-item">
                <Dragger
                  customRequest={dummyRequest}
                  showUploadList={false}
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => {
                    handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.BACK)
                  }}
                  style={{ border: backImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                >
                  {backImageData ? (
                    <img src={backImageData.full_link} alt="" />
                  ) : (
                    <>
                      <p className="top-sub">ID photo Back</p>
                      <p className="ant-upload-drag-icon">
                        <img src="/images/upload-file.png" alt="" />
                      </p>
                      <p className="ant-upload-text">You can click me to upload files!</p>
                    </>
                  )}
                </Dragger>
              </div>

              <div className="input-file-item">
                <p className="input-file-item-title">Portrait Photo</p>
                <p className="input-file-item-description">
                  Please make sure the content of the photo is complete and clearly visible <br />
                  Only supports JPG, JPEF,PNG, image formats <br />
                  Image size cannot exceed 5MB
                </p>
                <div className="box-input">
                  <Dragger
                    customRequest={dummyRequest}
                    showUploadList={false}
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => {
                      handleOnChangeImage(e, DOCUMENT_IMAGE_TYPE.SELFIE)
                    }}
                    style={{ border: selfieImageError ? '4px dashed rgba(255, 27, 0, 1)' : '' }}
                  >
                    {selfieImageData ? (
                      <img src={selfieImageData.full_link} alt="" />
                    ) : (
                      <>
                        <p className="ant-upload-drag-icon">
                          <img src="/images/upload-file.png" alt="" />
                        </p>
                        <p className="ant-upload-text">You can click me to upload files!</p>
                      </>
                    )}
                  </Dragger>

                  <div className="image-example">
                    <p>Example:</p>
                    <img src="/images/file-input.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <ButtonPrimary style={{ maxWidth: '150px' }} loading={loading} onClick={handleSubmitForm}>
                SUBMIT
              </ButtonPrimary>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default PrimaryKYC
