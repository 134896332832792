/* eslint-disable no-useless-concat */
/* eslint-disable no-plusplus */
import React, { Component } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'
import './style.scss'
import { roundNumber } from 'helpers'

am4core.useTheme(am4themesAnimated)

const defaultData = [
  {
    currency: 'MATIC',
    balance: 10,
    isActive: true,
    color: am4core.color('#5A3FFF'),
  },
  {
    currency: 'BNB',
    balance: 5,
    color: am4core.color('#FFF85B'),
  },
  {
    currency: 'ZUKI',
    balance: 45,
    isActive: true,
    color: am4core.color('#1ED6FF'),
  },
  {
    currency: 'ZP',
    balance: 30,
    color: am4core.color('#3DFFDC'),
  },
  {
    currency: 'Other',
    balance: 10,
    color: am4core.color('#A7B7DD'),
  },
]

class PieChart extends Component {
  componentDidMount() {
    const { isMobile, dataChart } = this.props
    const chart = am4core.create('chartdiv', am4charts.PieChart3D)
    chart.hiddenState.properties.opacity = 0 // this creates initial fade-in

    chart.data = dataChart.map((item, index) => ({
      ...defaultData[index],
      ...item,
    }))
    chart.innerRadius = am4core.percent(40)
    chart.depth = 20

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'right'
    chart.legend.fontSize = isMobile ? '12' : '16'
    chart.legend.labels.template.fill = '#FFFFFF'
    chart.legend.labels.template.marginLeft = '12px'
    chart.legend.labels.template.fontWeight = 'bold'
    chart.legend.valueLabels.template.fill = '#ffffff'
    chart.legend.valueLabels.template.marginLeft = '12px'

    const pieSeries = chart.series.push(new am4charts.PieSeries3D())
    // set key value
    pieSeries.dataFields.category = 'currency'
    pieSeries.dataFields.value = 'balance'
    pieSeries.dataFields.depthValue = 'balance'
    // select custom color at data
    pieSeries.slices.template.propertyFields.fill = 'color'
    // set default active key
    pieSeries.slices.template.propertyFields.isActive = 'isActive'

    // label outline
    pieSeries.labels.template.disabled = true
    pieSeries.ticks.template.disabled = true

    pieSeries.colors.step = 210

    // with of pie chart
    chart.innerRadius = am4core.percent(60)

    /**
     * label center chart
     */
    const label = pieSeries.createChild(am4core.Label)
    label.text = `\xa0 +${roundNumber(chart.data[0].balance, 1)}%\n[font-size:10px][/]`
    // label.text = `\xa0 +${chart.data[0].balance}%\n[font-size:10px]Yesterday's PNL[/]`
    label.fill = '#fff'
    label.horizontalCenter = 'middle'
    label.verticalCenter = 'middle'
    label.fontSize = 20
    label.fontWeight = 700

    /**
     * Check is one active
     * Click and set percent to (label center chart)
     */
    pieSeries.slices.template.events.on('hit', (ev) => {
      label.text = `\xa0 +${roundNumber(ev.target.dataItem.values.value.value, 1)}%\n[font-size:10px][/]`
      // label.text = `\xa0 +${ev.target.dataItem.values.value.value}%\n[font-size:10px]Yesterday's PNL[/]`
      const series = ev.target.dataItem.component
      series.slices.each((item) => {
        if (item.isActive && item !== ev.target) {
          item.isActive = false
        }
      })
    })

    /**
     * Tooltip
     */
    pieSeries.slices.template.tooltipText = '{currency}: {balance}% ({percent})'
    // pieSeries.slices.template.numberFormatter =

    // animation rotate
    pieSeries.hiddenState.properties.endAngle = -90
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose()
    }
  }

  render() {
    const { isMobile } = this.props
    return <div id="chartdiv" style={{ width: '100%', height: isMobile ? '200px' : '100%' }} />
  }
}

export default PieChart
