import React, { useMemo } from 'react'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import Skeleton from 'components/Skeleton'
import { isNumber, roundNumber } from 'helpers'
import { DECIMALS } from 'constants/index'

const CardBalance = ({ currency }) => {
  const totalBalance = useMemo(() => {
    if (currency) {
      return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
    }
    return undefined
  }, [currency])

  return (
    <div className="card-balance">
      <div className="card-header">
        <div className="balance-title">Balance</div>
        {/* <div className="card-percent" data-up="true"> 
        <ArrowUpIcon />
        2.36%
      </div> */}
      </div>
      <div className="card-content">
        {isNumber(totalBalance) ? (
          <>
            <span>USD</span>
            <CurrencyFormat value={roundNumber(totalBalance / 10 ** DECIMALS)} displayType="text" thousandSeparator />
          </>
        ) : (
          <Skeleton height="48px" />
        )}
      </div>
    </div>
  )
}

export default CardBalance
