import React, { useEffect } from 'react'
import './styles.scss'
import DefaultLayout from 'layouts/DefaultLayout'
import Banner from './component/banner'
import Service from './component/service'
import Support from './component/support'

const Home = () => {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'unset'
  }, [])
  return (
    <div className="home-container fadeIn">
      <Banner />
      <Service />
      <Support />
    </div>
  )
}

Home.Layout = DefaultLayout

export default Home
