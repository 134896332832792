/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import ProfileAccount from 'components/ProfileAccount'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import TotalBalance from '../TotalBalance'
import './style.scss'

const PrivateHeader = ({ links, isMobile, isLogin, showMenu, toggleMenu }) => {
  const history = useHistory()
  const { currency } = useSelector((state) => state.wallets)
  const baseToken = useMemo(() => currency?.find((item) => item.code === 'ZUKI'), [currency])

  return (
    <header className="private-header-mobile">
      <div className="header-left">
        {isMobile && (
          <Link to="/">
            <div className="header-logo">
              <img src="/images/logo-text.png" title="Zodi Pay" alt="Zodi Pay" />
            </div>
          </Link>
        )}
      </div>
      <div className="header-right">
        {!isMobile && <TotalBalance style={{ marginRight: '10px' }} baseToken={baseToken} />}

        {/* <Select
          className="select-chain"  
          options={[ 
            { label: 'BSC', value: 'BSC' },
            { label: 'Polygon', value: 'Polygon' },
          ]}
          placeholder="Select chian"
        /> */}

        <ProfileAccount hideName={isMobile} />

        {isMobile && (
          <div className="collapse-icon">
            <input type="checkbox" id="menu" checked={showMenu} onChange={toggleMenu} />
            <label htmlFor="menu" className="icon">
              <div className="menu" />
            </label>
          </div>
        )}
      </div>
      <div className="menu-content-mobile" data-visible={showMenu.toString()}>
        <div className="menu-body">
          <ul className="menu-link">
            {links.map((item, index) => {
              const isHttp = item?.href?.startsWith('http')
              const Tag = isHttp ? 'a' : Link
              const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
              return (
                <li
                  key={`menu-mb-${index}`}
                  className={history.location.pathname === item.href ? 'active' : ''}
                  role="presentation"
                  onClick={toggleMenu}
                >
                  <Tag {...propsLink} rel="noreferrer">
                    {item.label}
                  </Tag>
                </li>
              )
            })}
          </ul>
          {!isLogin && (
            <div className="menu-actions-mobile">
              <Link to="/register" onClick={toggleMenu}>
                <ButtonSecondary
                  size="larger"
                  style={{
                    color: history.location.pathname === '/register' ? '#000' : '#fff',
                    background: history.location.pathname === '/register' ? '#fff' : 'transparent',
                  }}
                >
                  Sign up
                </ButtonSecondary>
              </Link>
              <Link to="/login" onClick={toggleMenu}>
                <ButtonPrimary
                  size="larger"
                  style={{
                    color: history.location.pathname === '/login' ? '#000' : '#fff',
                    background: history.location.pathname === '/login' ? '#fff' : 'transparent',
                  }}
                >
                  Sign in
                </ButtonPrimary>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
export default PrivateHeader
