import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 43 43" fill="transparent" {...props}>
    <circle cx="21.5" cy="21.5" r="20.75" transform="rotate(90 21.5 21.5)" stroke="#34384C" strokeWidth="1.5" />
    <path
      d="M24.1468 32.0906L24.1468 9.22151C24.1468 9.07692 24.2659 8.95861 24.4115 8.95861L26.3965 8.95861C26.5421 8.95861 26.6612 9.07692 26.6612 9.22151L26.6612 29.1099L32.7157 24.3679C32.8878 24.2331 33.1458 24.3547 33.1458 24.5749L33.1458 26.9574C33.1458 27.1185 33.073 27.2696 32.944 27.3715L25.8606 32.9187C25.1658 33.461 24.1468 32.9713 24.1468 32.0906V32.0906ZM16.3388 32.8826L16.3388 12.9941L10.2843 17.7362C10.1122 17.8709 9.85417 17.7494 9.85417 17.5292L9.85417 15.1466C9.85417 14.9856 9.92695 14.8344 10.056 14.7326L17.1394 9.18536C17.8342 8.64313 18.8532 9.13278 18.8532 10.0102L18.8532 32.8826C18.8532 33.0272 18.7341 33.1455 18.5885 33.1455L16.6035 33.1455C16.4579 33.1455 16.3388 33.0272 16.3388 32.8826Z"
      fill="transparent"
      stroke="#fff"
    />
  </Svg>
)

export default Icon
