import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 20 20" fill="#fff" {...props}>
    <path
      d="M14.4834 8.64998V11.3416C14.4834 13.5833 13.5834 14.4833 11.3417 14.4833H8.65003C6.40836 14.4833 5.50836 13.5833 5.50836 11.3416V8.64998C5.50836 6.40832 6.40836 5.5083 8.65003 5.5083H11.3417C13.5917 5.51663 14.4834 6.40832 14.4834 8.64998Z"
      stroke="#2A4178"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 4.80841V7.50008C18.3333 9.74175 17.4333 10.6417 15.1917 10.6417H14.4833V8.65843C14.4833 6.41676 13.5833 5.51674 11.3417 5.51674H9.35834V4.80841C9.35834 2.56674 10.2583 1.66675 12.5 1.66675H15.1917C17.4333 1.66675 18.3333 2.56674 18.3333 4.80841Z"
      fill="#2A4178"
      stroke="#2A4178"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66669 15.1917V12.5001C1.66669 10.2584 2.56669 9.3584 4.80835 9.3584H5.51669V11.3417C5.51669 13.5834 6.41669 14.4834 8.65835 14.4834H10.6417V15.1917C10.6417 17.4334 9.74169 18.3334 7.50002 18.3334H4.80835C2.56669 18.3334 1.66669 17.4334 1.66669 15.1917Z"
      stroke="#2A4178"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default Icon
