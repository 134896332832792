import { AccountIcon, ExchangeIcon, SecurityIcon, StakingIcon, WalletIcon } from 'widgets/Svg'

export const linksPublic = [
  {
    label: 'Wallet',
    href: '/wallet',
    target: '',
  },
  {
    label: 'About',
    href: 'https://beurl.app/zodi-whitepaper',
    target: '_blank',
  },
  {
    label: 'Document',
    href: 'https://beurl.app/zodi-whitepaper',
    target: '_blank',
  },
]

export const linksPrivate = [
  {
    label: 'Wallet',
    href: '/wallet',
    target: '',
    icon: WalletIcon,
  },

  {
    label: 'Staking',
    href: '/staking',
    target: '',
    icon: StakingIcon,
  },
  {
    label: 'Exchange',
    href: '/exchange',
    target: '',
    icon: ExchangeIcon,
  },
  {
    label: 'Account',
    href: '/account',
    target: '',
    icon: AccountIcon,
  },
  // {
  //   label: 'Security',
  //   href: '/security',
  //   target: '',
  //   icon: SecurityIcon,
  // },
]

export const linksNavigatorMobile = [
  {
    label: 'Wallet',
    href: '/wallet',
    target: '',
    icon: WalletIcon,
  },
  {
    label: 'Staking',
    href: '/staking',
    target: '',
    icon: StakingIcon,
  },

  {
    label: 'Exchange',
    href: '/exchange',
    target: '',
    icon: ExchangeIcon,
  },
  {
    label: 'Account',
    href: '/account',
    target: '',
    icon: AccountIcon,
  },
  // {
  //   label: 'Security',
  //   href: '/security',
  //   target: '',
  //   icon: SecurityIcon,
  // },
]
