import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { isNumber } from 'lodash'
import './styles.scss'
import { DECIMALS } from 'constants/index'
import {
  createWallet,
  fetchWalletBalance,
  getCurrency,
  getCurrencyAttr,
  getWalletAddress,
  resendVerifyWithdrawCode,
  withdraw,
} from 'state/wallets/actions'
import BackLink from 'components/BackLink'
import Amount from './component/amount'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ControlInputSelect from 'components/ControlInputSelect'
import ControlInputText from 'components/ControlInputText'
import RecentTransaction from 'components/History/recentTransaction'
import ModalVerificationCode from 'components/ModalVerificationCode'
import { getTransactionList } from 'state/transactions/actions'
import useDetectAddress from 'hooks/useDetectAddress'
import { formatBigNumber } from 'helpers'

const Withdraw = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { userInfo } = useSelector((state) => state.user)
  const { chainList, currency, currencyAttr, walletsAddress } = useSelector((state) => state.wallets)
  const { rows, total } = useSelector((state) => state.transactions.transactionsList)

  const [modalVerifyCode, setModalVerifyCode] = useState({ toggle: false, dataModal: null })
  const [loadingWithdraw, setLoadingWithdraw] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const [amount, setAmount] = useState('')
  const [selectCurrency, setSelectCurrency] = useState('')
  const [selectChain, setSelectChain] = useState('')
  const [userTokenAddress, setUserTokenAddress] = useState('')
  const [walletAddressSelect, setWalletAddressSelect] = useState()

  const { isInSystem } = useDetectAddress(userTokenAddress)

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        type: item.type,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])
  const optionChain = useMemo(() => {
    if (chainList && currencyAttr?.length > 0) {
      return currencyAttr.map((currAttr) => {
        const fChain = chainList.find((o) => o._id === currAttr.blockchain)
        return {
          _id: fChain._id,
          label: fChain.title,
          value: fChain.chainid,
          chain_code: fChain.code,
          image: fChain.icon,
        }
      })
    }
    return undefined
  }, [chainList, currencyAttr])

  /* Clear all data when change coin */
  useEffect(() => {
    setAmount('')
    setUserTokenAddress()
  }, [selectCurrency])

  // Wallet currency  default select (any TOKEN)
  const baseCurrency = useMemo(() => {
    if (selectCurrency) {
      return currency?.find((item) => item.code === JSON.parse(selectCurrency).currency_code)
    }
    return undefined
  }, [currency, selectCurrency])

  // Chain attribute for get fee of chain
  const baseChainAttr = useMemo(() => {
    if (selectChain) {
      return currencyAttr?.find((item) => item.blockchain === JSON.parse(selectChain)._id)
    }
    return undefined
  }, [currencyAttr, selectChain])
  // Wallet default token of chain (BNB or MATIC)
  const walletChain = useMemo(() => {
    if (selectChain) {
      const pChain = JSON.parse(selectChain)
      if (pChain.chain_code === 'BEP20') {
        return currency?.find((item) => item.code === 'BNB')
      }
      if (pChain.chain_code === 'POLYGON') {
        return currency?.find((item) => item.code === 'MATIC')
      }
    }
    return undefined
  }, [currency, selectChain])

  /**
   * Handle set select chain when start app
   */
  useEffect(() => {
    if (optionCurrency && params?.id) {
      const fCurrency = optionCurrency.find((o) => o.value === params.id)
      setSelectCurrency(JSON.stringify(fCurrency))
    }
  }, [optionChain, optionCurrency, params?.id])

  /**
   * @dev Fetch attr for get fee by code of currency
   */
  useEffect(() => {
    if (baseCurrency?._id) {
      dispatch(
        getCurrencyAttr({
          currency_id: baseCurrency._id,
        }),
      )
    }
  }, [baseCurrency, dispatch])

  /**
   * Handle get address when enough field currency & chain (for check support chain)
   */
  useEffect(() => {
    if (walletsAddress && selectCurrency && selectChain) {
      const pCurrency = JSON.parse(selectCurrency)
      const pChain = JSON.parse(selectChain)

      const fWallet = walletsAddress.find((item) => {
        if (item.currency === pCurrency.currency_code && item.chain === pChain.chain_code) {
          return true
        }
        return false
      })
      if (fWallet) {
        setWalletAddressSelect(fWallet)
      } else {
        /**
         * Create new wallet if wallet is not available
         * Select wallet will be rerender and checked
         * After create new wallet this will make actions in the saga for fetch all new wallet (Search keyword: {yield put(actions.getWalletAddress})
         */
        dispatch(
          createWallet(
            {
              currency_code: pCurrency.currency_code,
              chain_code: pChain.chain_code,
              type: 'CRYPTO',
            },
            () => {
              dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
            },
            (error) => {
              if (error.code === 'err_not_found') {
                setWalletAddressSelect(undefined)
              }
            },
          ),
        )
      }
    }
  }, [dispatch, walletsAddress, selectCurrency, selectChain])

  /**
   * @dev Fetch txh history
   */
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    currency: undefined,
    type: 'WITHDRAW',
  })
  useEffect(() => {
    if (selectCurrency) {
      setTxParams((prev) => ({
        ...prev,
        currency: JSON.parse(selectCurrency).currency_code,
      }))
    }
  }, [selectCurrency])
  useEffect(() => {
    if (txParams.currency) {
      dispatch(getTransactionList(txParams))
    }
  }, [dispatch, txParams])

  /**
   * Re verify code for withdraw and resend code
   */
  const handleReVerifyWithdraw = useCallback(
    (item) => {
      dispatch(
        resendVerifyWithdrawCode({
          transaction_id: item._id,
        }),
      )
      setModalVerifyCode({ toggle: true, dataModal: item })
    },
    [dispatch],
  )

  /**
   * Verify code success withdraw
   */
  const handleFinishWithdraw = useCallback(() => {
    fetchWalletBalance([{ code: JSON.parse(selectCurrency).currency_code }])
    dispatch(getTransactionList(txParams))
  }, [dispatch, selectCurrency, txParams])

  const handleWithdraw = () => {
    if (!baseCurrency || !currencyAttr) return setErrorMess('An occurred error, Please try again')
    if (!selectChain) return setErrorMess('Please select network')
    if (!walletAddressSelect || !baseChainAttr) {
      return setErrorMess(`${JSON.parse(selectChain).label} is not support ${JSON.parse(selectCurrency).label}.`)
    }

    if (!selectCurrency) return setErrorMess('Please select currency')
    if (!userTokenAddress) return setErrorMess('Please enter receiver address')
    if (!isNumber(+amount)) return setErrorMess('Please enter amount')

    if (!userInfo?.kycInfo) {
      if (baseCurrency.usd_rate * amount > 10000) {
        return setErrorMess('Please complete KYC to increase your daily withdrawal limits to up to $100 000')
      }
    }
    if (baseCurrency.usd_rate * amount > 1000000) {
      return setErrorMess('Maximum amount (per transaction) $1000000')
    }

    const parseAmount = formatBigNumber(amount, DECIMALS)
    if (parseAmount < baseCurrency.min_withdraw) {
      return setErrorMess(`Minimum amount (per transaction) ${baseCurrency.min_withdraw / 10 ** DECIMALS}`)
    }
    if (parseAmount > baseCurrency.balance) return setErrorMess('Balance is not enough')

    let feeChain = 0
    let feeToken = 0

    feeChain = baseChainAttr.withdraw_fee_chain
    if (isInSystem) {
      feeChain = 0
    }

    // fee token is percent
    if (baseChainAttr?.withdraw_fee_token_type === 0) {
      feeToken = (baseChainAttr.withdraw_fee_token * parseAmount) / 100
    }
    // fee token is amount
    if (baseChainAttr?.withdraw_fee_token_type === 1) {
      feeToken = baseChainAttr.withdraw_fee_token
    }

    const nativeChainCode = currencyAttr.map((chain) => chain.native_token)

    if (nativeChainCode.includes(JSON.parse(selectCurrency).currency_code)) {
      if (feeChain + feeToken > baseCurrency.balance) return setErrorMess('Balance is not enough for pay fee')
    } else if (feeChain > walletChain.balance) {
      return setErrorMess(`${walletChain.title} is not enough for pay fee`)
    }

    setErrorMess('')
    setLoadingWithdraw(false)
    dispatch(
      withdraw(
        {
          amount: parseAmount,
          address: userTokenAddress,
          chain_code: JSON.parse(selectChain).chain_code,
          currency_code: JSON.parse(selectCurrency).currency_code,
        },
        (receipt) => {
          setModalVerifyCode({ toggle: true, dataModal: { _id: receipt.TransactionCode } })
          dispatch(getTransactionList(txParams))
          dispatch(getCurrency({ page: 1, pageSize: 100 }))
        },
        (error) => {
          if (error.code === 'err_data_existed') {
            const txHistory = rows.find((item) => item.status === 'CREATED')
            if (txHistory) {
              dispatch(
                resendVerifyWithdrawCode({
                  transaction_id: txHistory._id,
                }),
              )
              setModalVerifyCode({ toggle: true, dataModal: txHistory })
              return
            }
          }
          return null
        },
      ),
    )
  }

  return (
    <>
      <div className="warp-page-withdraw">
        <div className="page-withdraw-content">
          <div className="go-back">
            <BackLink label="Wallet" to="/wallet" />
          </div>

          <div className="withdraw-content-form">
            <div className="box-input-select">
              {optionCurrency && selectCurrency && (
                <ControlInputSelect
                  title="Select currency"
                  dataSelect={optionCurrency}
                  value={selectCurrency}
                  onChange={(value) => {
                    setSelectChain()
                    setWalletAddressSelect('')
                    setSelectCurrency(value)
                    history.replace(`/withdraw/${JSON.parse(value).currency_code}`)
                  }}
                />
              )}
              {optionChain && (
                <ControlInputSelect
                  title="Network"
                  dataSelect={optionChain}
                  placeholder="Select network"
                  value={selectChain || undefined}
                  onChange={(value) => {
                    setWalletAddressSelect('')
                    setSelectChain(value)
                  }}
                />
              )}
            </div>

            <div className="box-input-text">
              <ControlInputText
                label="Address"
                placeholder="Please enter the address below to withdraw"
                value={userTokenAddress}
                onChange={setUserTokenAddress}
              />
            </div>

            <div className="box-attention">
              <ul>
                <li>
                  <p>Enter the correct address to withdraw</p>
                </li>
                <li>
                  <p>Ensure the network is {selectChain && <span>{JSON.parse(selectChain).chain_code}</span>}</p>
                </li>
              </ul>
            </div>

            <Amount
              type="number"
              value={amount}
              isInSystem={isInSystem}
              baseCurrency={baseCurrency}
              is2Fa={userInfo?.status_2fa === 1}
              baseChainAttr={baseChainAttr}
              feeBNB={baseChainAttr?.withdraw_fee_chain !== undefined ? baseChainAttr?.withdraw_fee_chain / 10 ** DECIMALS : 0}
              feeToken={baseChainAttr?.withdraw_fee_token || 0}
              minimumAmount={baseChainAttr ? formatBigNumber(baseChainAttr.min_withdraw) : 0}
              onChange={setAmount}
            />

            <div className="box-discamler">
              <div>
                <img src="/images/icons/warring.png" alt="" />
              </div>
              <div>
                <p>Disclaimer</p>
                <p>
                  The network you have selected is BSC, please make sure your withdrawal address supports the BNB Smart Chain
                  network. In case the other platform does not support this network, your assets may be lost. If you are unsure if
                  the receiving platform supports this network, you can click the button below to verify it yourself.
                </p>
              </div>
            </div>

            <div className="withdraw-actions text-center">
              <p className="error-mess">{errorMess}</p>
              <ButtonPrimary style={{ maxWidth: '150px' }} loading={loadingWithdraw} onClick={handleWithdraw}>
                WITHDRAW
              </ButtonPrimary>
            </div>

            <RecentTransaction
              txParams={txParams}
              rows={rows}
              total={total}
              setTxParams={setTxParams}
              setModalVerifyCode={handleReVerifyWithdraw}
            />
          </div>
        </div>
      </div>

      <ModalVerificationCode
        visible={modalVerifyCode.toggle}
        dataModal={modalVerifyCode.dataModal}
        onCancel={setModalVerifyCode}
        onFinish={handleFinishWithdraw}
      />
    </>
  )
}

export default Withdraw
