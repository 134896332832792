import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import './styles.scss'
import { socials } from './config'
import InputWithButton from 'widgets/InputWithButton'

const Footer = () => (
  <footer className="footer-container">
    <div className="footer-content">
      <Row gutter={[20, 40]}>
        <Col xs={24} sm={24} lg={24} xl={8}>
          <Link to="/">
            <div className="footer-logo">
              <img src="/images/logo-text.png" title="Zodi Pay" alt="Zodi Pay" />
            </div>
          </Link>
          <p className="footer-des">Optimize your asset management process</p>
          <div className="socials-list">
            {socials.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <a key={item?.link_to + index} href={item?.link_to} target={item?.target} rel="noreferrer">
                <img src={item?.img_url} alt={item?.label} width={`${item?.width || '40px'}`} title={item?.label} />
              </a>
            ))}
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} xl={4}>
          <div className="quick-links">
            <div className="footer-title">Quick Links.</div>
            <ul>
              <li>
                <a href="https://beurl.app/zodi-whitepaper" target="_blank" rel="noreferrer" className="footer-sub-title">
                  About us
                </a>
              </li>
              <li>
                <a href="https://beurl.app/zodi-whitepaper" target="_blank" rel="noreferrer" className="footer-sub-title">
                  Document
                </a>
              </li>
              <li>
                <Link to="/wallet" rel="noreferrer" className="footer-sub-title">
                  Feature
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} xl={3}>
          <div className="quick-links">
            <div className="footer-title" style={{ opacity: 0 }}>
              Quick Links.
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} lg={12} xl={9}>
          <div className="footer-actions">
            <div className="footer-title">Subscribe to our newsletter</div>
            <p className="footer-actions-des">Subscribe to get updates and notifications on our exchanges and products</p>
            <InputWithButton button="Send" placeholder="Enter your email address" />
          </div>
        </Col>
      </Row>
      <div className="footer-bottom">
        <p>Zodi Pay ©. All rights reserved.</p>
        <div className="footer-bottom-right">
          <a href="https://beurl.app/zodi-term" target="_blank" rel="noreferrer">
            Term of Service
          </a>
          <span className="hr-hor" />
          <a href="https://beurl.app/zodi-privacy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
