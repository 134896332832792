import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 22 19" {...props}>
    <path
      d="M11.1853 0.104892C11.1633 0.0722166 11.1352 0.045792 11.1031 0.0276184C11.071 0.00944486 11.0357 0 11 0C10.9643 0 10.929 0.00944486 10.8969 0.0276184C10.8648 0.045792 10.8367 0.0722166 10.8147 0.104892L7.52059 4.94717C7.4 5.12487 7.50882 5.388 7.70588 5.388H9.88529V11.8945C9.88529 12.0449 9.99118 12.1679 10.1206 12.1679H11.8853C12.0147 12.1679 12.1206 12.0449 12.1206 11.8945V5.39141H14.2941C14.4912 5.39141 14.6 5.12828 14.4794 4.95058L12.8324 2.52774L11.1853 0.104892ZM21.7647 10.867H20C19.8706 10.867 19.7647 10.99 19.7647 11.1403V16.4029H2.23529V11.1403C2.23529 10.99 2.12941 10.867 2 10.867H0.235294C0.105882 10.867 0 10.99 0 11.1403V17.9065C0 18.5114 0.420588 19.0001 0.941176 19.0001H21.0588C21.5794 19.0001 22 18.5114 22 17.9065V11.1403C22 10.99 21.8941 10.867 21.7647 10.867Z"
      fill="#01FBFF"
    />
  </Svg>
)

export default Icon
