import React from 'react'
import { Table } from 'antd'
import { formatDate } from 'helpers'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    render: () => 'Last Login',
  },
  {
    title: 'OS',
    dataIndex: 'os',
  },
  {
    title: 'Ip',
    dataIndex: 'ip',
  },

  {
    title: 'Last Login',
    dataIndex: 'last_login',
    render: (text) => formatDate(text * 1000),
  },
]

export const HistoryTable = ({ paramsHistory, historyLogin, setParamsHistory }) => {
  if (!historyLogin) return <></>
  return (
    <Table
      columns={columns}
      dataSource={historyLogin.rows}
      scroll={{ x: 500 }}
      rowKey={(record) => record._id}
      pagination={{
        total: historyLogin.total,
        current: paramsHistory.pageIndex,
        position: ['bottomCenter'],
        showSizeChanger: false,
        onChange: (page, pageSize) => {
          setParamsHistory((prev) => ({
            ...prev,
            page,
            pageSize,
          }))
        },
      }}
    />
  )
}
