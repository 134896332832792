import './styles.scss'
import React from 'react'

const ComingSoon = () => (
  <div className="soon-container">
    <div className="content">
      <h1>COMING SOON</h1>
      <p>We will be celebrating the launch of our new site very soon!</p>
    </div>
  </div>
)

export default ComingSoon
