import './styles.scss'
import React from 'react'
import ButtonPrimary from '../ButtonPrimary'

const InputWithButton = ({ placeholder, button, size, ...props }) => (
  <div className="box-input" data-size={size || 'standard'} {...props}>
    <input placeholder={placeholder} />
    <ButtonPrimary style={{ maxWidth: '130px' }}>{button}</ButtonPrimary>
  </div>
)

export default InputWithButton
