import React from 'react'
import { useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { DECIMALS } from 'constants/index'
import { countDateToDate, roundNumber, formatDate } from 'helpers'
import ButtonPrimary from 'widgets/ButtonPrimary'

const ItemTransaction = ({ index, data, setViewDetails, setModalVerifyCode, ...props }) => {
  const { currency } = useSelector((state) => state.wallets)
  const fCurrency = currency?.find((curr) => curr._id === data?.currency_stake_id)

  return (
    <div className="WTransactionMobile" {...props}>
      <div className="item">
        <p className="left">No</p>
        <p className="right">{index + 1}</p>
        {/* <ButtonPrimary onClick={() => setViewDetails({ toggle: true, dataModal: data })}>UnStake</ButtonPrimary> */}
      </div>
      <div className="item">
        <p className="left">Currency</p>
        <div className="currency">
          <img className="logo" src={fCurrency.icon} alt="icon" />
          <span className="name">{fCurrency?.code || ''}</span>
        </div>
      </div>
      <div className="item">
        <p className="left">Amount</p>
        <div className="right">
          <p>
            <CurrencyFormat
              value={roundNumber(data?.amount_stake / 10 ** DECIMALS, 6)}
              suffix={fCurrency ? ` ${fCurrency?.code}` : ''}
              displayType="text"
              thousandSeparator
              renderText={(v) => v}
            />
          </p>
        </div>
      </div>
      <div className="item">
        <p className="left">Status</p>
        <div className="right">
          <ButtonPrimary
            className="box-status"
            data-status={data.status}
            // onClick={() =>
            //   data.status !== 'CREATED' ? setViewDetails({ toggle: true, dataModal: data }) : setModalVerifyCode(data)
            // }
          >
            {data.status}
          </ButtonPrimary>
        </div>
      </div>
      <div className="item">
        <p className="left">Lock Period</p>
        <div className="right">
          <p className="right"> {countDateToDate(data?.subscription_date * 1000, data?.redemption_date * 1000)}</p>
        </div>
      </div>
      <div className="item">
        <p className="left">Start</p>
        <div className="right">
          <p className="right">{formatDate(data?.subscription_date * 1000, 'YYYY/MM/DD')}</p>
        </div>
      </div>
      <div className="item">
        <p className="left">End</p>
        <div className="right">
          <p className="right">{formatDate(data?.redemption_date * 1000, 'YYYY/MM/DD')}</p>
        </div>
      </div>
      {data.status !== 'COMPLETED' && (
        <div className="item">
          <p className="left"> </p>
          <div className="right">
            <ButtonPrimary className="btn-unstake" onClick={() => setViewDetails({ toggle: true, dataModal: data })}>
              UNSTAKE
            </ButtonPrimary>
          </div>
        </div>
      )}
    </div>
  )
}

export default ItemTransaction
