/* eslint-disable no-case-declarations */
import produce from 'immer'
import { Field } from './const'
import * as types from './constants'

const initialState = {
  independentField: Field.INPUT,
  typedValue: '',
  [Field.INPUT]: {
    currencyId: undefined,
  },
  [Field.OUTPUT]: {
    currencyId: undefined,
  },
  recipient: null,
}

export default function ExchangeReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SELECT_CURRENCY:
        const { field, currencyId } = payload
        const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
        if (currencyId?.value === state[otherField].currencyId?.value) {
          // the case where we have to swap the order
          draft.independentField = state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
          draft[field] = { currencyId }
          draft[otherField] = { currencyId: state[field].currencyId }
        } else {
          draft[field] = { currencyId }
        }
        break
      case types.SWITCH_CURRENCIES:
        const newState = {
          independentField: draft.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [Field.INPUT]: { currencyId: draft[Field.OUTPUT].currencyId },
          [Field.OUTPUT]: { currencyId: draft[Field.INPUT].currencyId },
        }
        draft.independentField = newState.independentField
        draft[Field.INPUT] = newState[Field.INPUT]
        draft[Field.OUTPUT] = newState[Field.OUTPUT]
        break
      case types.TYPE_INPUT:
        draft.independentField = payload.field
        draft.typedValue = payload.typedValue
        break

      default:
        return { ...state }
    }
  })
}
