/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import ProfileAccount from 'components/ProfileAccount'
import React from 'react'
import { Link } from 'react-router-dom'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './style.scss'

const HeaderMobile = ({ visible, links, isLogin, location, toggleMenu }) => (
  <header className="header-container-mobile">
    <div className="header-mobile ">
      <div className="header-left">
        <Link
          to="/"
          onClick={() => {
            document.querySelector('body').style.overflow = 'unset'
          }}
        >
          <div className="header-logo">
            <img src="/images/logo-text.png" title="Zodi Pay" alt="Zodi Pay" />
          </div>
        </Link>
      </div>
      <div className="header-right">
        {isLogin && <ProfileAccount hideName />}
        <div className="collapse-icon">
          <input type="checkbox" id="menu" checked={visible} onChange={toggleMenu} />
          <label htmlFor="menu" className="icon">
            <div className="menu" />
          </label>
        </div>
      </div>
    </div>
    <div className="menu-content-mobile" data-visible={visible.toString()}>
      <div className="menu-body">
        <ul className="menu-link">
          {links.map((item, index) => {
            const isHttp = item?.href?.startsWith('http')
            const Tag = isHttp ? 'a' : Link
            const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
            return (
              <li
                key={`menu-mb-${index}`}
                className={location.pathname === item.href ? 'active' : ''}
                role="presentation"
                onClick={toggleMenu}
              >
                <Tag {...propsLink} rel="noreferrer">
                  {item.label}
                </Tag>
              </li>
            )
          })}
        </ul>
        {!isLogin && (
          <div className="menu-actions-mobile">
            <Link to="/register" onClick={toggleMenu}>
              <ButtonSecondary
                size="larger"
                style={{
                  color: location.pathname === '/register' ? '#000' : '#fff',
                  background: location.pathname === '/register' ? '#fff' : 'transparent',
                }}
              >
                Sign up
              </ButtonSecondary>
            </Link>
            <Link to="/login" onClick={toggleMenu}>
              <ButtonPrimary
                size="larger"
                style={{
                  color: location.pathname === '/login' ? '#000' : '#fff',
                  background: location.pathname === '/login' ? '#fff' : 'transparent',
                }}
              >
                Sign in
              </ButtonPrimary>
            </Link>
          </div>
        )}
      </div>
    </div>
  </header>
)

export default HeaderMobile
