import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import { Form } from 'antd'
import './styles.scss'

import { setAuthToken } from 'helpers/auth'
import { ArrowNext } from 'widgets/Svg'
import AuthCard from 'modules/auth/components/AuthCard'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import ButtonPrimary from 'widgets/ButtonPrimary'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import VerifyAuthenCode from 'modules/auth/components/VerifyAuthenCode'
import VerifyEmailCode from 'modules/auth/components/VerifyEmailCode'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { getProfile } from 'state/user/actions'
import { login } from 'state/auth/actions'

const SignIn = ({ history, location }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [formValue, setFormValue] = useState({})
  const [view, setView] = useState({ view: 0, dataView: null })
  const [loading, setLoading] = useState(false)

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true)
      dispatch(
        login(
          values,
          (receipt) => {
            if (receipt.is_email) {
              setView({ view: 1, dataView: receipt })
            }
            if (receipt.is_2fa) {
              setView({ view: 2, dataView: receipt })
            }
            setFormValue(values)
            setLoading(false)
          },
          (error) => {
            if (error?.code === 'err_email_active_required') {
              history.push('/register', { view: 1, email: values.email })
            }
            setLoading(false)
          },
        ),
      )
    })
  }

  const onFinishVerifyCode = useCallback(
    (receipt) => {
      const authToken = setAuthToken(receipt?.token)
      if (authToken) {
        dispatch(
          getProfile({}, () => {
            if (get(location, 'state.from')) {
              const pathName = get(location, 'state.from.pathname')
              const search = get(location, 'state.from.search')
              history.push(`${pathName}${search}`)
            } else {
              history.push('/wallet')
            }
          }),
        )
      }
    },
    [dispatch, history, location],
  )

  const onBack = useCallback(() => {
    setView({ view: 0, dataView: null })
  }, [])

  return (
    <AuthWrapper>
      <div className="wrapper-form-login">
        {view.view === 0 && (
          <AuthCard className="form-login fadeIn">
            <h2>Login</h2>
            <Form layout="vertical" form={form}>
              <FormItem
                label={<span>Email</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email field',
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" />
              </FormItem>
              <FormItem
                label={
                  <div className="wrapper-label">
                    <span>Password</span>
                    <Link to="/forgot-password">Forgot Password ?</Link>
                  </div>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter password field',
                  },
                ]}
              >
                <Input placeholder="**********" type="password" />
              </FormItem>
              <FormItem>
                <ButtonPrimary
                  type="submit"
                  className="form-action"
                  style={{ maxWidth: '150px' }}
                  loading={loading}
                  arrow={<ArrowNext width="24px" style={{ marginLeft: '10px' }} />}
                  onClick={onFormSubmit}
                >
                  SIGN IN
                </ButtonPrimary>
              </FormItem>
            </Form>
            <div className="form-links">
              I don’t have an account ?<Link to="/register">Sign up</Link>
            </div>
          </AuthCard>
        )}
        {view.view === 1 && (
          <VerifyEmailCode
            type="login"
            formValue={formValue}
            token={view.dataView?.token}
            onFinish={onFinishVerifyCode}
            onBack={onBack}
          />
        )}
        {view.view === 2 && <VerifyAuthenCode token={view.dataView?.token} onFinish={onFinishVerifyCode} />}
      </div>
    </AuthWrapper>
  )
}

export default SignIn
