import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { linksNavigatorMobile } from 'components/Menu/config'
import './style.scss'

const AVAILABLE_LINK = []
linksNavigatorMobile.forEach((item) => {
  AVAILABLE_LINK.push(item.href)
})

const NavigationMobile = () => {
  const history = useHistory()
  const path = history.location.pathname
  const [activePath, setActivePath] = useState(path)

  useEffect(() => {
    if (AVAILABLE_LINK.includes(path)) {
      setActivePath(path)
    }
  }, [path])

  return (
    <div className="navigation-mobile">
      <ul>
        {linksNavigatorMobile.map((item, index) => {
          const isHttp = item?.href?.startsWith('http')
          const Tag = isHttp ? 'a' : Link
          const propsLink = isHttp ? { href: item.href, target: item.target, relL: 'noreferrer' } : { to: item.href }
          const Icon = item.icon
          return (
            <li className={`list ${item.href === activePath ? 'active' : ''}`} key={`navigator-${index}`} role="presentation">
              <Tag {...propsLink}>
                <Icon />
                <span>{item.label}</span>
              </Tag>
            </li>
          )
        })}
        <div className="indicator" />
      </ul>
    </div>
  )
}

export default NavigationMobile
