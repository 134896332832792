import React from 'react'
import { Modal } from 'antd'
import './styles.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'

const ModalCustom = ({ className, onOke, loading, children, buttonLabel, ...props }) => (
  <Modal
    className={`custom-modal ${className}`}
    centered
    footer={
      onOke ? (
        <ButtonPrimary type="button" roles="presentation" loading={loading} onClick={onOke}>
          {buttonLabel}
        </ButtonPrimary>
      ) : (
        ''
      )
    }
    {...props}
  >
    {children}
  </Modal>
)

ModalCustom.defaultProps = {
  loading: false,
  buttonLabel: 'Confirm',
}

export default ModalCustom
