import React from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { getBscScanLink } from 'utils'
import ModalCustom from 'components/BaseModal'
import { DECIMALS } from 'constants/index'
import { formatCode, formatDate, roundNumber } from 'helpers'

const ModalViewDetails = ({ visible, dataModal, close }) => {
  if (!dataModal) return <></>
  return (
    <ModalCustom
      className="modal-deposit-details"
      title="Transaction Details"
      centered
      visible={Boolean(visible)}
      width={750}
      onCancel={() => close(false)}
    >
      <div className="modal-scan-action-bg" />

      <ul className="modal-deposit-details-steps">
        <li>
          <p>ORDER SUBMITTED</p>
          <p />
        </li>
        <li>
          <p>{dataModal.status}</p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </li>
      </ul>

      <div className="modal-deposit-details-note">
        <p>Please note that you will receive a email once it is complete.</p>
        <p>{/* Why hasn’t my deposit arrived? */}</p>
      </div>

      <div className="modal-deposit-details-info">
        <div className="modal-deposit-details-info-item">
          <p>Time</p>
          <p>{formatDate(dataModal.createdAt * 1000)}</p>
        </div>

        <div className="modal-deposit-details-info-item">
          <p>Network</p>
          <p>{dataModal.chain}</p>
        </div>

        <div className="modal-deposit-details-info-item">
          <p>Network Fee</p>
          <p>{dataModal.fee / 10 ** DECIMALS}</p>
        </div>

        <div className="modal-deposit-details-info-item">
          <p>Token</p>
          <p>{dataModal.currency}</p>
        </div>

        <div className="modal-deposit-details-info-item">
          <p>Status</p>
          <p>{dataModal.status}</p>
        </div>

        <div className="modal-deposit-details-info-item">
          <p>Amount</p>
          <p>
            <CurrencyFormat
              value={roundNumber(dataModal.amount / 10 ** DECIMALS, 6)}
              displayType="text"
              thousandSeparator
              renderText={(v) => v}
            />
          </p>
        </div>

        <div className="modal-deposit-details-info-item">
          <p>TxID</p>
          <p>
            {dataModal.status === 'COMPLETED' ? (
              <a
                className="recent-transaction-table-amount"
                href={getBscScanLink(dataModal.txhash, 'transaction', dataModal.chain)}
                target="_blank"
                rel="noreferrer"
              >
                {formatCode(dataModal.txhash, 5, 5)}
              </a>
            ) : (
              dataModal.status
            )}
          </p>
        </div>
      </div>
    </ModalCustom>
  )
}

export default ModalViewDetails
