import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { selectCurrency, switchCurrencies, typeInput } from './actions'

export function useSwapActionHandlers() {
  const dispatch = useDispatch()

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onCurrencySelection = useCallback(
    (field, currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency,
        }),
      )
    },
    [dispatch],
  )

  const onUserInput = useCallback(
    (field, typedValue) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch],
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
  }
}
