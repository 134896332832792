import produce from 'immer'
import * as Types from './constants'

const initialState = {
  transactionsList: {
    data: undefined,
    page: 0,
    total: 0,
  },
  listStatus: null,
}

export default function HomeReducer(state = initialState, action) {
  const { payload } = action
  return produce(state, (draft) => {
    switch (action.type) {
      case Types.GET_TRANSACTIONS_LIST_SUCCESS:
        draft.transactionsList = payload
        break

      case Types.GET_LIST_STATUS_SUCCESS:
        draft.listStatus = payload
        break

      default:
        return { ...state }
    }
  })
}
