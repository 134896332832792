import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, DatePicker, Space } from 'antd'
import moment from 'moment'
import './styles.scss'
import BackLink from 'components/BackLink'
import ControlInputSelect from 'components/ControlInputSelect'
import { getOrderList } from 'state/stake/actions'
import { removeEmpty } from 'helpers'
import { getCurrency } from 'state/wallets/actions'
import RecentTransaction from 'components/StakingHistory/recentTransaction'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { getListStatus } from 'state/transactions/actions'

const { RangePicker } = DatePicker

const Form = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { currency } = useSelector((state) => state.wallets)
  const { listStatus } = useSelector((state) => state.transactions)
  const { orderList } = useSelector((state) => state.stake)

  const [defaultInput, setDefaultInput] = useState({
    currencyInput: 'All',
    statusInput: 'All',
    dateInput: '',
  })

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        type: item.type,
        currency_id: item._id,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])

  const optionStatus = useMemo(() => {
    if (listStatus?.statusOrder) {
      return Object.keys(listStatus?.statusOrder).map((i) => ({
        label: i,
        value: i,
      }))
    }
    return undefined
  }, [listStatus])

  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    currency: '',
    currency_stake_id: '',
    status: undefined,
    start_date: '',
    end_date: '',
    status_url: false,
  })
  useEffect(() => {
    const parseSearch = Object.fromEntries(new URLSearchParams(location.search).entries())
    setTxParams((prev) => ({
      ...prev,
      ...parseSearch,
      status_url: true,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(getListStatus())
    // dispatch(getOrderList(txParams))
  }, [dispatch, txParams])

  useEffect(() => {
    if (txParams.status_url) {
      dispatch(getOrderList(removeEmpty(txParams)))
    }
  }, [dispatch, txParams])

  const handleSelectCurrency = (value) => {
    setTxParams((prev) => ({
      ...prev,
      currency_stake_id: value ? JSON.parse(value)?.currency_id : '',
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    if (value) {
      queryParams.set('currency_id', JSON.parse(value)?.currency_id)
      setDefaultInput({
        ...defaultInput,
        currencyInput: JSON.parse(value)?.value,
      })
    } else {
      queryParams.delete('currency_id')
    }
    history.replace(`?${queryParams.toString()}`)
  }

  const handleSelectStatus = (value) => {
    setTxParams((prev) => ({
      ...prev,
      status: value ? JSON.parse(value)?.value : '',
    }))
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', 1)
    queryParams.set('pageSize', 10)
    if (value) {
      queryParams.set('status', JSON.parse(value)?.value)
      setDefaultInput({
        ...defaultInput,
        statusInput: JSON.parse(value).value,
      })
    } else {
      queryParams.delete('status')
    }
    history.replace(`?${queryParams.toString()}`)
  }

  // Filter date
  const onChange = (dates, dateStrings) => {
    if (dates) {
      setTxParams((prev) => ({
        ...prev,
        page: 1,
        pageSize: 10,
        start_date: new Date(dateStrings[0]).getTime() / 1000,
        end_date: new Date(dateStrings[1]).getTime() / 1000,
        status_url: true,
      }))

      setDefaultInput({
        ...defaultInput,
        dateInput: dates,
      })
    } else {
      setTxParams((prev) => ({
        ...prev,
        start_date: '',
        end_date: '',
        status_url: true,
      }))
    }
  }

  // reset Filter
  const handleResetStakeHistory = () => {
    setTxParams((prev) => ({
      ...prev,
      page: 1,
      pageSize: 10,
      currency: '',
      currency_stake_id: '',
      status: undefined,
      start_date: '',
      end_date: '',
    }))
    setDefaultInput({
      currencyInput: 'All',
      statusInput: 'All',
      dateInput: '',
    })
    history.replace(`/stake/stake-history`)
  }

  const handleUnStakeSuccess = () => {
    dispatch(getOrderList(removeEmpty(txParams)))
    dispatch(getCurrency({ page: 1, pageSize: 100 }))
  }

  return (
    <div className="warp-history-form-staking">
      <div className="go-back">
        <BackLink label="Staking History" to="/staking" />
      </div>

      <ButtonPrimary className="filter-reset-btn" onClick={() => handleResetStakeHistory()}>
        Reset
      </ButtonPrimary>

      <div className="history-form">
        <Row
          gutter={[
            { xs: 8, sm: 8, md: 32, lg: 32 },
            { xs: 8, sm: 16, md: 32, lg: 32 },
          ]}
          className="history-form-filter"
        >
          <Col xs={12} sm={11} md={8} lg={8}>
            <div className="box-history-title">
              <ControlInputSelect
                title="Currency"
                value={defaultInput.currencyInput}
                dataSelect={optionCurrency}
                defaultValue="All"
                onChange={(value) => handleSelectCurrency(value)}
              />
            </div>
          </Col>
          <Col xs={12} sm={11} md={8} lg={{ span: 8 }}>
            <div className="box-history-title">
              <ControlInputSelect
                title="Status"
                dataSelect={optionStatus}
                value={defaultInput.statusInput}
                defaultValue="All"
                onChange={(value) => handleSelectStatus(value)}
              />
            </div>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8}>
            <div className="box-history-title">
              <p>Date</p>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RangePicker
                  onChange={onChange}
                  placeholder={['1 Jan 2021', '3 May 2022']}
                  style={{ background: '#000', borderColor: '#34384C', borderRadius: '8px' }}
                  format="YYYY/MM/DD"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear={false}
                  value={[moment(defaultInput?.dateInput[0]), moment(defaultInput?.dateInput[1])]}
                />
              </Space>
            </div>
          </Col>
        </Row>

        <RecentTransaction
          orderList={orderList}
          rows={orderList?.rows}
          txParams={txParams}
          setTxParams={setTxParams}
          onUnstakeSuccess={handleUnStakeSuccess}
        />
      </div>
    </div>
  )
}

export default Form
