import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import ListCardTotalInfo from './components/ListCardTotalInfo'
import HeaderHelper from './components/HeaderHelper'
import StakeList from './components/StakeList'

import './style.scss'

const Stake = () => {
  useEffect(() => {
    document.querySelector('.private-content').scrollTo(0, 0)
  }, [])

  return (
    <div className="stake-container fadeIn">
      <Row
        gutter={[
          { xs: 16, md: 32 },
          { xs: 16, md: 32 },
        ]}
      >
        <Col span={24}>
          <ListCardTotalInfo />
        </Col>
        <Col span={24}>
          <HeaderHelper />
        </Col>
        <Col span={24}>
          <StakeList />
        </Col>
      </Row>
    </div>
  )
}

export default Stake
