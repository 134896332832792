/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Col, Row, Form, Select, DatePicker } from 'antd'
import './styles.scss'
import FormItem from 'widgets/FormItem'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ButtonPrimary from 'widgets/ButtonPrimary'
import PointSecurity from 'components/PointSecurity'
import ModalVerificationEmailCode from 'components/ModalVerificationEmailCode'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { getListCountry } from 'state/app/actions'
import { getProfile, updateProfile } from 'state/user/actions'
import { requestForgotPassword, logout } from 'state/auth/actions'
import { uploadFile } from 'state/file/actions'
import { getBase64 } from 'helpers'

const { Option } = Select

const Account = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState(false)
  const [form] = Form.useForm()
  const { userInfo } = useSelector((state) => state.user)
  const { listCountry } = useSelector((state) => state.app)
  const [inputName, setInputName] = useState(`${userInfo.profile.fistname} ${userInfo.profile.lastname}`)
  const [inputBirthday, setInputBirthday] = useState(
    `${moment(userInfo.profile.birthday)._d}`.toUpperCase() !== 'Invalid Date'.toUpperCase()
      ? userInfo.profile.birthday
      : '2000-05-25',
  )
  const [openModalChagnePassword, setOpenModalChangePassword] = useState(false)
  const [avatar, setAvatar] = useState('')
  const [timeResend, setTimeResend] = useState(0)

  const dataSelectCountry = listCountry?.map((item, index) => (
    <Option key={index} value={item.code}>
      {item.title}
    </Option>
  ))

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true)
      dispatch(
        updateProfile(
          {
            ...values,
            fistname: inputName.split(' ').slice(0, -1).join(' '),
            lastname: inputName.split(' ').slice(-1).join(' '),
            birthday: inputBirthday,
            avatar: avatar._id,
          },
          () => {
            dispatch(getProfile())
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    })
  }

  useEffect(() => {
    if (userInfo) {
      const defaultValues = {
        country: userInfo.profile.country,
        phone_number: userInfo.profile.phone_number,
        address: userInfo.profile.address,
        birthday: userInfo.profile.birthday,
      }
      setAvatar({
        image: userInfo.profile.avatar,
      })
      form.setFieldsValue(defaultValues)
    }
  }, [form, userInfo])

  const handleChangeName = (value) => {
    setInputName(value)
  }

  const handleChangeDate = (date) => {
    setInputBirthday(date)
  }

  const handleChangePassword = () => {
    if (timeResend === 0) {
      dispatch(
        requestForgotPassword(
          {
            email: userInfo.email,
          },
          () => {
            setOpenModalChangePassword(true)
            setTimeResend(50)
          },
        ),
        [],
      )
    } else {
      setOpenModalChangePassword(true)
    }
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleChangeAvatar = (event) => {
    if (event.target.files && event.target.files[0]) {
      getBase64(event.target.files[0]).then(() => {
        dispatch(
          uploadFile(
            {
              key: 1,
              image: event.target.files[0],
            },
            (receipt) => {
              setAvatar({
                ...receipt,
                image: receipt.full_link,
              })
              //   dispatch(
              //     updateProfile(
              //       {
              //         token_captcha: tokenCaptcha,
              //         avatar: receipt._id,
              //       },
              //       () => {
              //         dispatch(getProfile())
              //       },
              //     ),
              //   )
            },
          ),
        )
      })
    }
  }

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeResend((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="warp-page-account">
        <div className="page-account-content">
          <div className="box-avatar">
            <div className="avatar-content-left">
              <div className="avatar-image">
                <img src={avatar?.image ? `${avatar.image}` : '/images/user-default.png'} alt="" />

                <label htmlFor="change_avatar">
                  <input
                    id="change_avatar"
                    accept="image/*"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      handleChangeAvatar(e)
                    }}
                  />
                  <img src="/images/icons/icon-edit.png" alt="" role="presentation" />
                </label>
              </div>
              <div className="avatar-info">
                <p>
                  {name ? (
                    <input autoFocus defaultValue={inputName} onChange={(event) => handleChangeName(event.target.value)} />
                  ) : (
                    inputName
                  )}

                  <img
                    src="/images/icons/icon-edit.png"
                    alt=""
                    role="presentation"
                    onClick={() => {
                      if (name) {
                        setName(false)
                      } else {
                        setName(true)
                      }

                      document.getElementById('username')?.focus()
                    }}
                  />
                </p>
                <p>{userInfo.email}</p>
              </div>
            </div>
            <div className="avatar-content-right">
              <ButtonSecondary style={{ width: '150px' }} onClick={handleLogout}>
                Log out
              </ButtonSecondary>
            </div>
          </div>

          <div className="box-info-input">
            <Form layout="vertical" form={form}>
              <Row gutter={[{ sm: 15, md: 32, lg: 57 }, 32]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="input-width-pen">
                    <div className="box-input">
                      <FormItem
                        label={<span>Country</span>}
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter country field',
                          },
                        ]}
                      >
                        <Select
                          id="country"
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Country"
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {dataSelectCountry}
                        </Select>
                      </FormItem>
                      <img
                        style={{ transform: 'translateY(-10%)' }}
                        src="/images/icons/icon-pen.png"
                        alt=""
                        role="presentation"
                        onClick={() => {
                          document.getElementById('country')?.focus()
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="input-width-pen">
                    <div className="box-input">
                      <FormItem
                        label={<span>Phone Number</span>}
                        name="phone_number"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Phone Number field',
                          },
                        ]}
                      >
                        <input id="phone-number" placeholder="Phone Number" />
                      </FormItem>
                      <img
                        style={{ transform: 'translateY(-10%)' }}
                        src="/images/icons/icon-pen.png"
                        alt=""
                        role="presentation"
                        onClick={() => {
                          document.getElementById('phone-number')?.focus()
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="input-width-pen">
                    <div className="box-input">
                      <FormItem
                        label={<span>Address</span>}
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Address field',
                          },
                        ]}
                      >
                        <input id="address" placeholder="address" />
                      </FormItem>
                      <img
                        style={{ transform: 'translateY(-10%)' }}
                        src="/images/icons/icon-pen.png"
                        alt=""
                        role="presentation"
                        onClick={() => {
                          document.getElementById('address')?.focus()
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                  <div className="input-width-pen">
                    <div className="box-input">
                      <p style={{ marginBottom: '13px' }}>Birthday</p>
                      <div style={{ position: 'relative' }}>
                        <DatePicker
                          id="birthday"
                          defaultValue={moment(inputBirthday)}
                          style={{ width: '100%', padding: '0' }}
                          onChange={(even, date) => handleChangeDate(date)}
                        />
                        <img
                          src="/images/icons/icon-pen.png"
                          alt=""
                          role="presentation"
                          onClick={() => {
                            document.getElementById('birthday')?.focus()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="text-center">
                <ButtonPrimary
                  style={{
                    width: '150px',
                    marginTop: '50px',
                  }}
                  loading={loading}
                  onClick={onFormSubmit}
                >
                  Save
                </ButtonPrimary>
              </div>
            </Form>
          </div>

          <div className="box-info-password">
            <div className="password-left">
              <p>
                Password{' '}
                <ButtonSecondary style={{ width: '122px' }} onClick={handleChangePassword}>
                  Change
                </ButtonSecondary>
              </p>
              <p>Increase your password strength to enhance account security</p>
            </div>
            <div className="password-right">
              <ButtonSecondary style={{ width: '122px' }} onClick={handleChangePassword}>
                Change
              </ButtonSecondary>
            </div>
          </div>

          <div className="box-rate-sercurity">
            <Link to="/wallet">
              <div className="rate-sercurity-item">
                <div className="rate-sercurity-item-name">
                  <img src="/images/account/wallet.png" alt="Wallet" />
                  <p>Wallet</p>
                </div>
                <div className="rate-sercurity-item-description">
                  <p>Your assets will be safely kept here and the multi-chain makes it easy to use</p>
                </div>
              </div>
            </Link>

            <Link to="/security">
              <div className="rate-sercurity-item">
                <div className="rate-sercurity-item-name">
                  <img src="/images/account/shield.png" alt="Security" />
                  <p>Security</p>
                </div>
                <div className="rate-sercurity-item-description">
                  <p>
                    Account risk level:
                    {!userInfo?.status_2fa ? (
                      <PointSecurity point={3} />
                    ) : !userInfo?.status_kyc ? (
                      <PointSecurity point={4} />
                    ) : (
                      <PointSecurity point={5} />
                    )}
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div className="log-out">
            <ButtonSecondary style={{ width: '150px' }}>Log out</ButtonSecondary>
          </div>
        </div>
      </div>
      <ModalVerificationEmailCode
        visible={Boolean(openModalChagnePassword)}
        email={userInfo.email}
        close={setOpenModalChangePassword}
        timeResend={timeResend}
        setTimeResend={setTimeResend}
      />
    </>
  )
}

export default Account
