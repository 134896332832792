import React from 'react'
import './styles.scss'
import { Row, Col } from 'antd'
import TitleSection from '../titleSection'

const dataSupport = [
  {
    id: 1,
    name: 'Binance',
    image: '/images/Home/our-support/binance.png',
  },
  {
    id: 2,
    name: 'Polygon',
    image: '/images/Home/our-support/polygon.png',
  },
  {
    id: 3,
    name: 'Zukimoba',
    image: '/images/Home/our-support/zuki.png',
  },
  {
    id: 4,
    name: 'Zodichain',
    image: '/images/Home/our-support/zodipay.png',
  },
  {
    id: 5,
    name: 'Coming soon',
    image: '/images/Home/our-support/soon.png',
  },
]

const Support = () => (
  <div className="warp-support">
    <img className="halo" src="/images/Home/our-support/halo.png" alt="" />

    <TitleSection title="Our Support" description="We will collaborate with a lot more projects to expand our ecosystem" />

    <div className="warp-support-content">
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 32, lg: 48 },
          { xs: 8, sm: 16, md: 32, lg: 48 },
        ]}
        justify="center"
      >
        {dataSupport.map((item, index) => (
          <Col key={`support-${index}`} xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <div className="support-item">
              <img src={item.image} alt={item.name} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  </div>
)

export default Support
