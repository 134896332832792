import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import './style.scss'
import { DECIMALS } from 'constants/index'
import { EyeIcon, EyeSleepIcon } from 'widgets/Svg'
import { roundNumber, isNumber } from 'helpers'

const TotalBalance = ({ baseToken, ...props }) => {
  const { currency } = useSelector((state) => state.wallets)
  const [show, setShow] = useState(true)
  const totalBalance = useMemo(() => {
    if (currency) {
      return currency.reduce((total, current) => total + current.balance * current.usd_rate, 0)
    }
    return undefined
  }, [currency])
  return (
    <div className="total-balance" {...props}>
      <div onClick={() => setShow((prev) => !prev)} role="presentation">
        <span>
          {show && isNumber(totalBalance) ? (
            <>
              <CurrencyFormat
                value={roundNumber(totalBalance / 10 ** DECIMALS)}
                displayType="text"
                thousandSeparator
                prefix={`$ `}
              />
            </>
          ) : (
            <span style={{ marginTop: '7px', display: 'block' }}>********</span>
          )}
        </span>
        {show ? <EyeIcon /> : <EyeSleepIcon />}
      </div>
    </div>
  )
}

export default TotalBalance
