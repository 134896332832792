import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 16 6" {...props}>
    <path
      d="M0.166113 3.94434H12.7744L9.7682 0.44306C9.68278 0.343571 9.75986 0.194336 9.89945 0.194336H11.2494C11.4536 0.194336 11.6474 0.280433 11.7724 0.427755L15.189 4.40735C15.5328 4.80913 15.2224 5.39842 14.6661 5.39842H0.166113C0.0744464 5.39842 -0.000553588 5.32954 -0.000553588 5.24536V4.0974C-0.000553588 4.01321 0.0744464 3.94434 0.166113 3.94434Z"
      fill="black"
    />
  </Svg>
)

export default Icon
