import React from 'react'
import './style.scss'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import VerifyAuthenCode from 'modules/auth/components/VerifyAuthenCode'

const VerifyCode = () => (
  <AuthWrapper>
    <div className="wrapper-form-verify">
      <VerifyAuthenCode />
    </div>
  </AuthWrapper>
)

export default VerifyCode
