import React from 'react'
import { Link } from 'react-router-dom'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './style.scss'

const HeaderHelper = () => (
  <div className="stake-header-helper-container">
    <div className="stake-header-helper-body" style={{ backgroundImage: 'url(/images/staking/header.png)' }}>
      <div className="stake-header-helper-content">
        <h3 className="stake-header-helper-title">Staking And Earn</h3>
        <p className="stake-header-helper-des">Staking, dedicated to increasing user staking income</p>
        <Link to="#" target="_blank" rel="noreferrer">
          <ButtonSecondary className="stake-header-helper-action">What is Stake ?</ButtonSecondary>
        </Link>
      </div>
    </div>
  </div>
)

export default HeaderHelper
