import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form } from 'antd'
import './styles.scss'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import ButtonPrimary from 'widgets/ButtonPrimary'
import AuthenCode from 'modules/auth/components/VerifyResetPasswordCode'
import { requestForgotPassword } from 'state/auth/actions'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [view, setView] = useState(0)
  const [email, setEmail] = useState('')

  const onFormSubmit = () => {
    form.validateFields().then((values) => {
      dispatch(
        requestForgotPassword(
          {
            ...values,
          },
          () => {
            setEmail(values.email)
            setView(1)
          },
        ),
      )
    })
  }

  return (
    <AuthWrapper>
      <div className="wrapper-form-forgot">
        {view === 0 ? (
          <AuthCard className="form-forgot fadeIn">
            <h2>Forgot Password</h2>
            <Form layout="vertical" form={form} onFinish={onFormSubmit}>
              <FormItem
                label={<span>Enter your email address</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email field',
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" type="email" />
              </FormItem>

              <FormItem>
                <ButtonPrimary type="submit" className="form-action" style={{ maxWidth: '150px' }}>
                  Continue
                </ButtonPrimary>
              </FormItem>
            </Form>
            <div className="form-links">
              Already have Account. ?<Link to="/login">Sign up</Link>
            </div>
          </AuthCard>
        ) : (
          <AuthenCode email={email} token={view?.data?.token} />
        )}
      </div>
    </AuthWrapper>
  )
}

export default ForgotPassword
