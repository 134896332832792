import React from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { formatDate, roundNumber } from 'helpers'
import { DECIMALS } from 'constants/index'

const ItemTransaction = ({ data, setViewDetails, setModalVerifyCode, ...props }) => (
  <div className="item-recent-transaction" {...props}>
    <div>
      <p>{formatDate(data.createdAt * 1000)}</p>

      {data.status !== 'CREATED' ? (
        <a role="presentation" onClick={() => setViewDetails({ toggle: true, dataModal: data })}>
          View
        </a>
      ) : (
        <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(data)}>
          Verify
        </a>
      )}
    </div>
    <div>
      <p>Action</p>
      <p>{data.action}</p>
    </div>
    <div>
      <p>Network</p>
      <p>{data.chain}</p>
    </div>
    <div>
      <p>Fee</p>
      <p>{data.fee / 10 ** DECIMALS}</p>
    </div>
    <div>
      <p>Status</p>
      <p data-status={data.status}>
        <span>{data.status}</span>
      </p>
    </div>

    <div>
      <p>Token</p>
      <p>{data.currency}</p>
    </div>
    <div>
      <p>Amount</p>
      <p>
        <CurrencyFormat
          value={roundNumber(data.amount / 10 ** DECIMALS, 6)}
          displayType="text"
          thousandSeparator
          renderText={(v) => v}
        />
      </p>
    </div>
  </div>
)

export default ItemTransaction
