import { useEffect, useState } from 'react'

const isMatchedMobile = '(max-width: 768px)'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(() => window.matchMedia(isMatchedMobile).matches)
  useEffect(() => {
    // Create listeners for each media query returning a function to unsubscribe
    function handlers() {
      const mql = window.matchMedia(isMatchedMobile)

      const handler = (matchMediaQuery) => {
        setIsMobile(matchMediaQuery.matches)
      }

      // Safari < 14 fix
      if (mql.addEventListener) {
        mql.addEventListener('change', handler)
      }

      return () => {
        // Safari < 14 fix
        if (mql.removeEventListener) {
          mql.removeEventListener('change', handler)
        }
      }
    }
    handlers()

    return () => {
      handlers()
    }
  }, [])

  return isMobile
}

export default useIsMobile
