import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { roundNumber } from 'helpers'
import { Field } from './const'

export function useExchangeState() {
  return useSelector((state) => state.exchange)
}

function useTradeAmount(typedValue, inputCurrency, outputCurrency) {
  return useMemo(() => {
    let outputAmount
    if (inputCurrency && outputCurrency) {
      outputAmount = (typedValue * inputCurrency?.price) / outputCurrency?.price
    }
    return {
      inputAmount: typedValue,
      outputAmount: outputAmount ? roundNumber(outputAmount, { scale: 3 }) : undefined,
    }
  }, [typedValue, inputCurrency, outputCurrency])
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedExchangeInfo(typedValue, inputCurrency, outputCurrency) {
  const parsedAmount = typedValue ?? ''

  const { inputAmount, outputAmount } = useTradeAmount(typedValue, inputCurrency, outputCurrency)

  const currencies = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  let inputError
  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  return {
    currencies,
    inputAmount,
    outputAmount,
    inputError,
  }
}
