import './styles.scss'
import React from 'react'
import { Table } from 'antd'

const TableRecentTransactionDesktop = ({ columns, dataSource, txParams, setTxParams, orderList, ...props }) => (
  <Table
    className="TableRecentTransactionDesktop"
    columns={columns}
    dataSource={dataSource}
    scroll={{ x: 800, y: 400 }}
    pagination={{
      total: orderList?.total,
      current: txParams?.page,
      // showSizeChanger: true,
      showTotal: (total) => `${total} items`,
      onChange: (page, pageSize) =>
        setTxParams((prev) => ({
          ...prev,
          page,
          pageSize,
        })),
    }}
    {...props}
  />
)

export default TableRecentTransactionDesktop
