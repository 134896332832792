import './styles.scss'
import React from 'react'

const ControlInputText = ({ label, placeholder, onChange, ...props }) => (
  <div className="input-text-item">
    <p>{label}</p>
    <input placeholder={placeholder} onChange={(e) => onChange(e.target.value)} {...props} />
  </div>
)

ControlInputText.defaultProps = {
  placeholder: '',
  onChange: () => null,
}

export default ControlInputText
