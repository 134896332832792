import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { linksPublic } from 'components/Menu/config'
import FooterAuth from '../FooterAuth'
import './styles.scss'
import { Resizable } from 're-resizable'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ButtonPrimary from 'widgets/ButtonPrimary'
import WalletLogin from 'components/WalletLogin'

const AuthWrapper = ({ children }) => {
  const location = useLocation()
  return (
    <div className="auth-container">
      <div className="auth-content-left">
        <div className="auth-content-img">
          <WalletLogin />
        </div>
        <div className="auth-actions">
          <Link to="/login">
            <ButtonPrimary
              size="larger"
              style={{
                color: location.pathname === '/login' ? '#000' : '#fff',
                background: location.pathname === '/login' ? '#fff' : 'transparent',
              }}
            >
              SIGN IN
            </ButtonPrimary>
          </Link>
          <Link to="/register">
            <ButtonSecondary
              size="larger"
              style={{
                color: location.pathname === '/register' ? '#000' : '#fff',
                background: location.pathname === '/register' ? '#fff' : 'transparent',
              }}
            >
              SIGN UP
            </ButtonSecondary>
          </Link>
        </div>
      </div>
      <Resizable
        className="auth-content-right"
        defaultSize={{ width: '100%', height: 530 }}
        minHeight={40}
        maxHeight="100vh"
        enable={{ top: true }}
        handleWrapperClass="auth-drag"
        handleComponent={{
          top: (
            <div className="auth-drag-item">
              <div />
            </div>
          ),
        }}
      >
        <div className="auth-body-right">
          {children}
          <FooterAuth links={linksPublic} location={location} />
        </div>
      </Resizable>
    </div>
  )
}

export default AuthWrapper
