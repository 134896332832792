import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Row, Col } from 'antd'
import './styles.scss'
import BackLink from 'components/BackLink'
import ControlInputSelect from 'components/ControlInputSelect'
import RecentTransaction from 'components/History/recentTransaction'
import { createWallet, getCurrencyAttr, getWalletAddress } from 'state/wallets/actions'
import { getTransactionList } from 'state/transactions/actions'
import Address from '../address'

const Form = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { chainList, currency, walletsAddress, currencyAttr } = useSelector((state) => state.wallets)
  const { rows, total } = useSelector((state) => state.transactions.transactionsList)

  const [selectCurrency, setSelectCurrency] = useState()
  const [selectChain, setSelectChain] = useState()
  const [walletAddressSelect, setWalletAddressSelect] = useState()

  const optionCurrency = useMemo(() => {
    if (currency) {
      return currency.map((item) => ({
        label: item.title,
        value: item.code,
        type: item.type,
        currency_code: item.code,
        image: item.icon,
      }))
    }
    return undefined
  }, [currency])
  const optionChain = useMemo(() => {
    if (chainList && currencyAttr?.length > 0) {
      return currencyAttr.map((currAttr) => {
        const fChain = chainList.find((o) => o._id === currAttr.blockchain)
        return {
          _id: fChain._id,
          label: fChain.title,
          value: fChain.chainid,
          chain_code: fChain.code,
          image: fChain.icon,
        }
      })
    }
    return []
  }, [chainList, currencyAttr])

  // Wallet currency  default select (any TOKEN)
  const baseCurrency = useMemo(() => {
    if (selectCurrency) {
      return currency?.find((item) => item.code === JSON.parse(selectCurrency).currency_code)
    }
    return undefined
  }, [currency, selectCurrency])

  /**
   * Handle set select chain when start app
   */
  useEffect(() => {
    // if (optionChain) {
    //   setSelectChain(JSON.stringify(optionChain[0]))
    // }
    if (optionCurrency && params?.id) {
      const fCurrency = optionCurrency.find((o) => o.value === params.id)
      setSelectCurrency(JSON.stringify(fCurrency))
    }
  }, [optionChain, optionCurrency, params?.id])

  /**
   * @dev Fetch attr for get fee by code of currency
   */
  useEffect(() => {
    if (baseCurrency?._id) {
      dispatch(
        getCurrencyAttr({
          currency_id: baseCurrency._id,
        }),
      )
    }
  }, [baseCurrency, dispatch])
  /**
   * Handle get address when enough field currency & chain
   */
  useEffect(() => {
    if (walletsAddress && selectCurrency && selectChain) {
      const pCurrency = JSON.parse(selectCurrency)
      const pChain = JSON.parse(selectChain)

      // find wallet address
      const fWallet = walletsAddress.find((item) => {
        if (item.currency === pCurrency.currency_code && item.chain === pChain.chain_code) {
          return true
        }
        return false
      })
      // select if find it
      if (fWallet) {
        setWalletAddressSelect(fWallet)
      } else {
        // Create new address if not find
        /**
         * Create new wallet if wallet is not available
         * Select wallet will be rerender and checked
         * After create new wallet this will make actions in the saga for fetch all new wallet (Search keyword: {yield put(actions.getWalletAddress})
         */
        dispatch(
          createWallet(
            {
              currency_code: pCurrency.currency_code,
              chain_code: pChain.chain_code,
              type: 'CRYPTO',
            },
            () => {
              dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
            },
            (error) => {
              if (error.code === 'err_not_found') {
                setWalletAddressSelect(undefined)
              }
            },
          ),
        )
      }
    }
  }, [dispatch, walletsAddress, selectCurrency, selectChain])

  /**
   * Fetch txh history
   */
  const [txParams, setTxParams] = useState({
    page: 1,
    pageSize: 10,
    currency: undefined,
    type: 'DEPOSIT',
  })
  useEffect(() => {
    if (selectCurrency) {
      setTxParams((prev) => ({
        ...prev,
        currency: JSON.parse(selectCurrency).currency_code,
      }))
    }
  }, [selectCurrency])

  useEffect(() => {
    if (txParams.currency) {
      dispatch(getTransactionList(txParams))
    }
  }, [dispatch, txParams])

  return (
    <div className="warp-deposit-form">
      <div className="go-back">
        <BackLink label="Wallet" to="/wallet" />
      </div>

      <div className="deposit-form">
        <Row gutter={[16, 16]} className="deposit-form-top">
          <Col span={24}>
            <div className="box-select-deposit">
              {optionCurrency && selectCurrency && (
                <ControlInputSelect
                  title="Select currency"
                  dataSelect={optionCurrency}
                  value={selectCurrency}
                  onChange={(value) => {
                    setSelectChain()
                    setWalletAddressSelect('')
                    setSelectCurrency(value)
                    history.replace(`/deposit/${JSON.parse(value).currency_code}`)
                  }}
                />
              )}
              {optionChain && (
                <ControlInputSelect
                  title="Deposit to"
                  dataSelect={optionChain}
                  placeholder="Select network"
                  value={selectChain || undefined}
                  onChange={(value) => {
                    setWalletAddressSelect('')
                    setSelectChain(value)
                  }}
                />
              )}
            </div>

            <div className="box-qr-code-title">
              <p>Address</p>
              <p>Use the wallet address below to deposit</p>
            </div>
          </Col>
        </Row>

        <Address text={walletAddressSelect?.address} />

        <div className="box-attention">
          <ul>
            <li>{selectCurrency && <p>Send only {JSON.parse(selectCurrency).currency_code} to this deposit address</p>}</li>
            <li>
              <p>Ensure the network is {selectChain && <span>{JSON.parse(selectChain).chain_code}</span>}</p>
            </li>
          </ul>
        </div>

        <div className="box-expected">
          <div className="item-expected">
            <p>Expected arrival</p>
            <p>15 network confirmations</p>
          </div>

          <div className="item-expected">
            <p>Expected unlock</p>
            <p>15 network confirmations</p>
          </div>
        </div>

        {/* <DepositSuccessView dataEnd={rows?.[0]} />  */}

        <RecentTransaction txParams={txParams} rows={rows} total={total} setTxParams={setTxParams} />
      </div>
    </div>
  )
}

export default Form
