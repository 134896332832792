/* eslint-disable no-extend-native */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { ToastContainer } from 'react-toastify'
import 'antd/dist/antd.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-multi-carousel/lib/styles.css'
import './assets/css/global.scss'
import { languages } from 'language/config'
import { PrivateLayout, PublicLayout } from 'layouts'
import { getAuthToken } from 'helpers/auth'
import fetchHelper from 'helpers/FetchHelper'
import SignIn from 'modules/auth/pages/Signin'
import SignUp from 'modules/auth/pages/Signup'
import ForgotPassword from 'modules/auth/pages/ForgotPassword'
import ResetPassword from 'modules/auth/pages/ResetPassword'
import Page404 from 'modules/404'
import Home from 'modules/home'
import Account from 'modules/account'
import Wallet from 'modules/wallet'
import Security from 'modules/security'
import PrimaryKYC from 'modules/primaryKYC'
import Withdraw from 'modules/withdraw'
import VerifyCode from 'modules/auth/pages/VerifyCode'
import NewPassword from 'modules/auth/pages/NewPassword'
import Deposit from 'modules/deposit'
import Exchange from 'modules/exchange'
import { useConfigRecaptcha } from 'helpers/GoogleRecaptcha'
import { getProfile } from 'state/user/actions'
import { getListCountry } from 'state/app/actions'
import { getChainList, getCurrency, getWalletAddress } from 'state/wallets/actions'
import Stake from 'modules/stake'
import StakeHistory from 'modules/stakeHistory'
import ComingSoon from 'modules/comingSoon'
import Maintenance from 'modules/maintenance'

const isLogin = () => {
  const authToken = getAuthToken()
  if (authToken) {
    fetchHelper.addDefaultHeader('Authorization', `Bearer ${authToken}`)
  }
  return Boolean(authToken)
}

const DefaultRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PublicLayout
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...rest} isLogin={isLogin()}>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PublicLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: '/wallet', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const Layout = Component.Layout || PrivateLayout
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Layout {...rest} isLogin={isLogin()}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

function App() {
  const dispatch = useDispatch()
  const { language } = useSelector((state) => state.user)

  useConfigRecaptcha()

  const hadValidToken = isLogin()

  useEffect(() => {
    if (hadValidToken) {
      dispatch(getChainList())
      dispatch(getListCountry())
      dispatch(getProfile())
    }
  }, [dispatch, hadValidToken])

  /**
   * Fetch wallet
   */
  useEffect(() => {
    if (hadValidToken) {
      dispatch(getCurrency({ page: 1, pageSize: 100 }))
      dispatch(getWalletAddress({ page: 1, pageSize: 100 }))
    }
  }, [dispatch, hadValidToken])

  return (
    <>
      <ToastContainer />
      <IntlProvider locale={language || 'en'} messages={languages[language]}>
        <Router>
          <Switch>
            {process.env.REACT_APP_MAINTENANCE === 'true' && <Route path="*" component={Maintenance} />}
            {process.env.REACT_APP_COMING_SOON === 'true' && <Route path="*" component={ComingSoon} />}

            {/* All */}
            <DefaultRoute exact path="/" component={Home} />
            <DefaultRoute exact path="/404" component={Page404} />
            <DefaultRoute exact path="/coming-soon" component={ComingSoon} />

            {/* Public */}
            <PublicRoute exact path="/register" component={SignUp} />
            <PublicRoute exact path="/login" component={SignIn} />
            <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
            <PublicRoute exact path="/reset-password" component={ResetPassword} />
            <PublicRoute exact path="/verify-code" component={VerifyCode} />
            <PublicRoute exact path="/new-password" component={NewPassword} />

            {/* Private */}
            <PrivateRoute exact path="/account" component={Account} />
            <PrivateRoute exact path="/wallet" component={Wallet} />
            <PrivateRoute exact path="/staking" component={Stake} />
            <PrivateRoute exact path="/stake/stake-history" component={StakeHistory} />
            <PrivateRoute exact path="/deposit/:id" component={Deposit} />
            <PrivateRoute exact path="/withdraw/:id" component={Withdraw} />
            <PrivateRoute exact path="/security" component={Security} />
            <PrivateRoute exact path="/primary-kyc" component={PrimaryKYC} />
            <PrivateRoute exact path="/exchange" component={Exchange} />

            {/* Other */}
            <Route path="" component={() => <Redirect to="/wallet" />} />
          </Switch>
          {/* <Switch>
            {process.env.REACT_APP_BUILD_MOBILE !== 'true' && <DefaultRoute exact path="/" component={Home} />}
            <PublicRoute exact path="/login" component={ComingSoon} />
            <PublicRoute exact path="/register" component={ComingSoon} />
            <DefaultRoute exact path="/coming-soon" component={ComingSoon} />
            <Route path="" component={() => <Redirect to={process.env.REACT_APP_BUILD_MOBILE !== 'true' ? '/' : '/login'} />} />
          </Switch> */}
        </Router>
      </IntlProvider>
    </>
  )
}

export default App
