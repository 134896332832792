import React from 'react'
import CurrencyFormat from 'react-currency-format'
import { roundNumber } from 'helpers'
import './styles.scss'

const Amount = ({
  baseCurrency,
  isInSystem,
  feeBNB,
  baseChainAttr,
  feeToken,
  minimumAmount,
  is2Fa,
  value,
  onChange,
  ...props
}) => (
  <div className="box-amount">
    <p className="amount-title">Amount</p>
    <div className="amount-avaiable">
      <p>{!is2Fa && 'Please do 2FA for increase your daily withdraw limits to up to $10 000'}</p>
      <p>
        Available:{' '}
        <span>
          {baseCurrency?.balance !== undefined ? (
            <>
              <CurrencyFormat
                value={roundNumber(baseCurrency.balance, { decimals: 18 })}
                displayType="text"
                thousandSeparator
                renderText={(v) => v}
              />{' '}
              {baseCurrency.title}
            </>
          ) : (
            '--'
          )}
        </span>
      </p>
    </div>

    <div className="box-input-amount">
      <input
        value={value}
        placeholder={`Minimum amount (per transaction) ${minimumAmount}`}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
      {baseCurrency?.balance !== undefined && (
        <button type="button" onClick={() => onChange(roundNumber(baseCurrency.balance, { decimals: 18 }))}>
          Max
        </button>
      )}
    </div>

    <div className="amount-fee">
      <p>
        Network fee: <span>{isInSystem ? 0 : feeBNB}</span>
      </p>
      <p>
        Token fee:{` `}
        {baseCurrency && feeToken ? (
          <span>
            {(() => {
              if (baseChainAttr?.withdraw_fee_token_type === 0) {
                return `${roundNumber(feeToken)}% ${baseCurrency?.code}`
              }
              if (baseChainAttr?.withdraw_fee_token_type === 1) {
                return `${roundNumber(feeToken, { decimals: 18, scale: 2 })} ${baseCurrency?.code}`
              }
              return '0'
            })()}
          </span>
        ) : (
          '0'
        )}
      </p>
    </div>
  </div>
)

export default Amount
