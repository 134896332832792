import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 17 17" {...props}>
    <path d="M14.194 11.9404C16.0013 10.3275 17 8.5 17 8.5C17 8.5 13.8125 2.65625 8.50002 2.65625C7.47959 2.65976 6.4707 2.87239 5.53564 3.281L6.35377 4.10019C7.04167 3.8496 7.7679 3.72053 8.50002 3.71875C10.7525 3.71875 12.6215 4.95975 13.991 6.32931C14.6501 6.99198 15.241 7.7192 15.7548 8.5C15.6931 8.59244 15.6251 8.69444 15.5476 8.806C15.1916 9.316 14.6657 9.996 13.991 10.6707C13.8157 10.846 13.633 11.0192 13.4417 11.1871L14.194 11.9404Z" />
    <path d="M12.0031 9.74951C12.2402 9.08634 12.2841 8.36948 12.1297 7.68233C11.9753 6.99518 11.629 6.36601 11.131 5.86801C10.633 5.37001 10.0038 5.02366 9.31663 4.86927C8.62948 4.71487 7.91262 4.75878 7.24945 4.99589L8.12388 5.87032C8.53221 5.81188 8.94854 5.84933 9.33989 5.97972C9.73123 6.11011 10.0868 6.32985 10.3785 6.62152C10.6702 6.9132 10.8899 7.26879 11.0203 7.66013C11.1507 8.05148 11.1881 8.46781 11.1297 8.87614L12.0031 9.74951ZM8.87614 11.1297L9.74951 12.0031C9.08634 12.2402 8.36948 12.2841 7.68233 12.1297C6.99518 11.9753 6.36601 11.629 5.86801 11.131C5.37001 10.633 5.02366 10.0038 4.86927 9.31663C4.71487 8.62948 4.75878 7.91262 4.99589 7.24945L5.87032 8.12388C5.81188 8.53221 5.84933 8.94854 5.97972 9.33989C6.11011 9.73123 6.32985 10.0868 6.62152 10.3785C6.9132 10.6702 7.26879 10.8899 7.66013 11.0203C8.05148 11.1507 8.46781 11.1881 8.87614 11.1297Z" />
    <path d="M3.55937 5.8119C3.36812 5.9819 3.18431 6.15403 3.009 6.32934C2.34987 6.99201 1.75899 7.71922 1.24525 8.50003L1.45244 8.80603C1.80838 9.31603 2.33431 9.99603 3.009 10.6707C4.37856 12.0403 6.24856 13.2813 8.5 13.2813C9.26075 13.2813 9.97688 13.14 10.6463 12.8988L11.4644 13.719C10.5293 14.1276 9.52043 14.3402 8.5 14.3438C3.1875 14.3438 0 8.50003 0 8.50003C0 8.50003 0.997688 6.67146 2.80606 5.05965L3.55831 5.81296L3.55937 5.8119ZM14.4989 15.2512L1.74888 2.50115L2.50113 1.7489L15.2511 14.4989L14.4989 15.2512Z" />
  </Svg>
)

export default Icon
