import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import './styles.scss'
import ButtonSecondary from 'widgets/ButtonSecondary'
import ModalTurnOn2FA, { MODAlTYPE } from 'components/ModalTurnOn2FA'
import { create2FA, getHistoryLogin } from 'state/auth/actions'
import { HistoryTable } from 'components/HistoryTable'

const SECURITY_TYPE = {
  EMAIL: 'email',
  AUTHY: 'authy',
}

const Security = () => {
  const dispatch = useDispatch()
  const { historyLogin } = useSelector((state) => state.auth)
  const { userInfo } = useSelector((state) => state.user)

  const [modal2FA, setModal2FA] = useState({ toggle: false, dataModal: null, type: '' })

  const handleTurnOnTypeVerify = (type) => {
    switch (type) {
      case SECURITY_TYPE.EMAIL:
        break
      case SECURITY_TYPE.AUTHY:
        if (userInfo.status_2fa === 0) {
          dispatch(
            create2FA(
              (receipt) => {
                setModal2FA({ toggle: true, dataModal: receipt, type: MODAlTYPE.VERIFY })
              },
              (e) => {
                toast.error(e?.message || 'Has an error, Please try again late.')
              },
            ),
          )
        } else {
          setModal2FA({ toggle: true, dataModal: {}, type: MODAlTYPE.UPDATE })
        }
        break
      default:
        break
    }
  }

  const handleDisableAccount = () => {
    // Soon
  }

  const [paramsHistory, setParamsHistory] = useState({
    page: 1,
    pageSize: 10,
  })
  useEffect(() => {
    dispatch(getHistoryLogin(paramsHistory))
  }, [dispatch, paramsHistory])

  return (
    <div className="warp-page-security">
      <div className="page-security-content">
        <div className="page-security-content-header">
          <div className="box-kyc">
            <p>
              Primary KYC{' '}
              {(() => {
                if (userInfo?.status_kyc === 1) {
                  return (
                    <ButtonSecondary style={{ maxWidth: '118px' }} hoverAble={false}>
                      Processing
                    </ButtonSecondary>
                  )
                }
                if (userInfo?.status_kyc === 2) {
                  return (
                    <ButtonSecondary style={{ maxWidth: '118px' }} hoverAble={false}>
                      Approved
                    </ButtonSecondary>
                  )
                }
                return (
                  <Link to="/primary-kyc">
                    <ButtonSecondary style={{ maxWidth: '118px' }}>Verify</ButtonSecondary>
                  </Link>
                )
              })()}
            </p>
            <p>Increase your 24-hour withdrawal limit up to $1000000</p>
          </div>

          <div className="info-kyc">
            <div className="info-item">
              <span>Nationality: </span>
              <span>{userInfo?.profile?.country || '--'}</span>
            </div>

            <div className="info-item">
              <span>Name: </span>
              <span>{userInfo?.profile ? `${userInfo?.profile?.fistname} ${userInfo?.profile?.lastname}` : '--'}</span>
            </div>

            <div className="info-item">
              <span>ID Type: </span>
              <span>{userInfo?.kycInfo?.type || '--'}</span>
            </div>

            <div className="info-item">
              <span>ID Number: </span>
              <span>{userInfo?.kycInfo?.number || '--'}</span>
            </div>
          </div>
        </div>

        <div className="page-security-content-body">
          <div className="security-item">
            <p>
              Email Verification
              <ButtonSecondary
                className="btn-verify"
                style={{ maxWidth: '118px' }}
                hoverAble={false}
                // onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.EMAIL)}
              >
                Default
              </ButtonSecondary>
            </p>
            <p>Increase your password strength to enhance account security</p>
          </div>

          <div className="security-item">
            <p>
              Zodi Pay/Google Authenticator
              {/* 0: chưa 1: active 2: disable */}
              {userInfo.status_2fa === 1 ? (
                <ButtonSecondary
                  className="btn-disable"
                  style={{ maxWidth: '118px' }}
                  onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}
                >
                  Disable
                </ButtonSecondary>
              ) : (
                <ButtonSecondary
                  className="btn-verify"
                  style={{ maxWidth: '118px' }}
                  onClick={() => handleTurnOnTypeVerify(SECURITY_TYPE.AUTHY)}
                >
                  Verify
                </ButtonSecondary>
              )}
            </p>
            <p>Set up your Zodi Pay/Google Authenticator to provide an extra security</p>
          </div>

          <div className="security-item">
            <p>
              Disable Account
              <ButtonSecondary hoverAble={false} style={{ maxWidth: '118px' }} onClick={handleDisableAccount}>
                Soon
              </ButtonSecondary>
            </p>
            <p>Disable your account immediately</p>
          </div>

          <div className="security-item">
            <p>
              Account Activity
              {/* <ButtonSecondary style={{ maxWidth: '118px' }}>More</ButtonSecondary> */}
            </p>
            <HistoryTable paramsHistory={paramsHistory} historyLogin={historyLogin} setParamsHistory={setParamsHistory} />
          </div>
        </div>
      </div>
      <ModalTurnOn2FA
        visible={modal2FA.toggle}
        dataModal={modal2FA.dataModal}
        modalType={modal2FA.type}
        onCancel={() => setModal2FA({ toggle: false, dataModal: null })}
      />
    </div>
  )
}

export default Security
