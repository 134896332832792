import { useEffect } from 'react'
// import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import FormatAmount from 'components/FormatAmount'
import { orderStakeReport } from 'state/stake/actions'
import StakeCardOverview from '../StakeCardOverview'
import './style.scss'
import { roundNumber } from 'helpers'

const ListCardTotalInfo = () => {
  const dispatch = useDispatch()

  const { totalStake, totalStakeSystem, totalEarnedHarvest, totalStakingSystem } = useSelector((state) => state.stake.orderReport)

  useEffect(() => {
    dispatch(orderStakeReport())
  }, [dispatch])
  return (
    <div className="stake-list-card-container">
      <StakeCardOverview
        title="Self Stake"
        content={
          <FormatAmount
            value={roundNumber(totalStake, { decimals: 18 })}
            displayType="text"
            prefix="$"
            thousandSeparator
            renderText={(v) => v}
          />
        }
        url="/stake/stake-history"
        viewAll
      />
      <StakeCardOverview
        title="System Staked"
        content={
          <FormatAmount
            value={roundNumber(totalStakeSystem, { decimals: 18 })}
            displayType="text"
            prefix="$"
            thousandSeparator
            renderText={(v) => v}
          />
        }
      />
      <StakeCardOverview
        title="Total Reward"
        content={
          <FormatAmount
            value={roundNumber(totalEarnedHarvest, { decimals: 18 })}
            displayType="text"
            prefix="$"
            thousandSeparator
            renderText={(v) => v}
          />
        }
      />
      <StakeCardOverview title="Staking" content={totalStakingSystem} />
    </div>
  )
}
export default ListCardTotalInfo
