import React from 'react'
import Svg from '../Svg'

const Icon = (props) => (
  <Svg viewBox="0 0 18 18" {...props}>
    <path d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 2.7C10.494 2.7 11.7 3.906 11.7 5.4C11.7 6.894 10.494 8.1 9 8.1C7.506 8.1 6.3 6.894 6.3 5.4C6.3 3.906 7.506 2.7 9 2.7ZM9 15.48C7.93067 15.48 6.87797 15.2154 5.93575 14.7097C4.99354 14.2041 4.1911 13.4731 3.6 12.582C3.627 10.791 7.2 9.81 9 9.81C10.791 9.81 14.373 10.791 14.4 12.582C13.8089 13.4731 13.0065 14.2041 12.0642 14.7097C11.122 15.2154 10.0693 15.48 9 15.48Z" />
  </Svg>
)

export default Icon
