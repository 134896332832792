/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import numeral from 'numeral'
import { roundTo } from 'round-to'
import BigNumber from 'bignumber.js'
import momentTimezone from 'moment-timezone'

export const currentTimestamp = () => new Date().getTime()
export const removeEmpty = (obj) =>
  Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? removeEmpty(v) : v])
    // eslint-disable-next-line eqeqeq
    .reduce((a, [k, v]) => (v == null || v == undefined || v == '' ? a : ((a[k] = v), a)), {})

export const formatCode = (text, start, end, concat = '...') => {
  if (!text) return text
  const total = start + end
  const textStr = text.toString()
  const { length } = textStr
  if (total >= length) return text
  return [textStr.slice(0, start), textStr.slice(length - end)].join(concat)
}

export const formatDate = (date, format = 'HH:mm YYYY/MM/DD') => {
  // const country = JSON.parse(localStorage.getItem("userInfo"));
  const country = 'Asia/Ho_Chi_Minh'
  if (date) {
    const tz = momentTimezone(date)
    const time = tz.tz(country).format(format)
    return time
  }
  return ''
}
// ========================== Number =================================== //
export const isNumber = (value) => {
  if (value !== undefined && value !== null && !Number.isNaN(value)) {
    return true
  }
  return false
}

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_TWO = new BigNumber(2)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

/**
 * Method to format the display of wei given an EthersBigNumber object
 * Note: does NOT round
 */
export const formatBigNumber = (number, decimals = -18) => {
  if (!number) return number
  const remainder = new BigNumber(number).shiftedBy(decimals).toString()
  return +remainder
}
export function roundNumber(n, options = {}) {
  const { scale = 2, decimals } = {
    scale: 2,
    ...options,
  }
  if (!isNumber(n)) return 0
  if (n && decimals) {
    n = new BigNumber(n).shiftedBy(-decimals).toNumber()
  }
  if (+n > 1e17) return Math.round(+n)
  const num = typeof +n !== 'number' ? 0 : parseFloat(n)
  if (!`${num}`.includes('e')) {
    return +`${Math.floor(`${num}e+${scale}`)}e-${scale}`
  }
  const arr = `${num}`.split('e')
  let sig = ''
  if (+arr[1] + scale > 0) {
    sig = '+'
  }
  return +`${Math.floor(`${+arr[0]}e${sig}${+arr[1] + scale}`)}e-${scale}`
}
export function formatNumber(nb, options) {
  const { scale = 3, decimals } = options || {
    scale: 3,
  }
  if (!isNumber(nb)) return 0
  if (nb && decimals) {
    nb = new BigNumber(nb).shiftedBy(-decimals).toNumber()
  }

  const n = roundTo.up(parseFloat(nb), scale)

  const sign = +n < 0 ? '-' : ''
  const toStr = n.toString()
  if (!/e/i.test(toStr)) {
    return n
  }
  const [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./)
  return +pow < 0
    ? `${sign}0.${'0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0))}${lead}${decimal}`
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : `${decimal.slice(0, +pow)}.${decimal.slice(+pow)}`)
}
// ================================================================================
// Returns first 2 digits after first non-zero decimal
// i.e. 0.001286 -> 0.0012, 0.9845 -> 0.98, 0.0102 -> 0.010, etc
// Intended to be used for tokens whose value is less than $1
// https://stackoverflow.com/a/23887837
export const getFirstThreeNonZeroDecimals = (value) => value.toFixed(9).match(/^-?\d*\.?0*\d{0,2}/)[0]

// export type formatAmountNotation = 'compact' | 'standard'

/**
 * This function is used to format token prices, liquidity, amount of tokens in TX, and in general any numbers on info section
 * @param amount - amount to be formatted
 * @param notation - whether to show 1M or 1,000,000
 * @param displayThreshold - threshold below which it will return simply <displayThreshold instead of actual value, e.g. if 0.001 -> returns <0.001 for 0.0005
 * @param tokenPrecision - set to true when you want precision to be 3 decimals for values < 1 and 2 decimals for values > 1
 * @param isInteger - if true the values will contain decimal part only if the amount is > 1000
 * @returns formatted string ready to be displayed
 */
export const formatAmount = (amount, options) => {
  const { notation = 'compact', displayThreshold, tokenPrecision, isInteger, roundUp, decimals, scale } = options || {
    notation: 'compact',
  }
  if (amount === 0) {
    if (isInteger) {
      return '0'
    }
    return '0.00'
  }
  if (!amount) return '--'
  if (amount && decimals) {
    amount = new BigNumber(amount).shiftedBy(-decimals).toNumber()
  }
  if (displayThreshold && amount < displayThreshold) {
    return `<${displayThreshold}`
  }
  if (amount < 1 && !tokenPrecision) {
    return getFirstThreeNonZeroDecimals(amount)
  }

  let precision = 2
  if (tokenPrecision) {
    precision = amount < 1 ? 3 : 2
  }

  let format = `0,0.${'0'.repeat(precision)}a`

  if (notation === 'standard') {
    format = `0,0.${'0'.repeat(precision)}`
  }

  if (scale) {
    format = `0,0.[${'0'.repeat(precision)}]a`
  }

  if (isInteger && amount < 1000) {
    format = '0'
  }

  /**
   * @dev Check round down
   * Not working with number => 1e20
   */
  if (amount > 999 && !roundUp) {
    const amountStr = Math.trunc(amount).toString()
    const i = Math.floor(Math.log(amount) / Math.log(1000))
    const fistCount = Math.floor(amount / 1000 ** i).toString().length + precision
    amount = Number(amountStr.substring(0, fistCount) + '0'.repeat(amountStr.substring(fistCount, amountStr.length).length))
  }

  const amountWithPrecision = parseFloat(amount?.toFixed(precision))

  // toUpperCase is needed cause numeral doesn't have support for capital K M B out of the box
  return numeral(amountWithPrecision).format(format).toUpperCase()
}

// ========================== Date =================================== //
// Date.prototype.addDays = function (days) {
//   const date = new Date(this.valueOf())
//   date.setDate(date.getDate() + days)
//   return date
// }
export function addDays(time, days) {
  const date = new Date(time)
  date.setDate(date.getDate() + days)
  return date
}

export function getDateToDate(t0, t1) {
  const d = new Date(t1).getTime() - new Date(t0).getTime()
  const weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7)
  const days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7)
  const hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24)
  const minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60)
  const seconds = Math.floor(d / 1000 - weekdays * 7 * 24 * 60 * 60 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60)
  const milliseconds = Math.floor(
    d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000,
  )
  const t = {
    end: d <= 0,
  }

  Object.entries({ weekdays, days, hours, minutes, seconds, milliseconds }).forEach(([key, value]) => {
    if (d > 0) {
      t[key] = value
    } else {
      t[key] = 0
    }
  })
  return t
}

export function countDateToDate(t0, t1) {
  const { weekdays, days, hours, minutes, seconds } = getDateToDate(t0, t1)
  if (weekdays || days) {
    return `${weekdays * 7 + days} days`
  }
  if (hours) {
    return `${hours}.${minutes} hours`
  }
  if (minutes) {
    return `${minutes}.${seconds} minutes`
  }
  return `${seconds} seconds`
}

// ========================== File =================================== //
export const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const getBase64 = async (img) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result))
    reader.readAsDataURL(img)
  })

export const onLoadImage = (url) =>
  new Promise((resolve, reject) => {
    if (!url) reject(new Error('Uri is null'))
    if (typeof url !== 'string' || !url?.startsWith('http')) reject(new Error('Is not uri'))
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
  })
