import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './styles.scss'
import TitleSection from '../titleSection'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { ArrowNext } from 'widgets/Svg'

const Banner = () => {
  const { userInfo } = useSelector((state) => state.user)
  return (
    <div className="warp-banner">
      <TitleSection
        title="THE MOST TRUSTED MULTICHAIN AND MULTIFEATURED WALLET"
        description="Simplify your crypto asset management and allow transferring your tokens among multiple blockchains"
      />
      <img src="/images/Home/banner/banner.png" alt="" />
      <div style={{ textAlign: 'center' }}>
        {userInfo ? (
          <Link to="/wallet">
            <ButtonPrimary style={{ maxWidth: '150px' }} arrow={<ArrowNext width="24px" style={{ marginLeft: '10px' }} />}>
              Wallet
            </ButtonPrimary>
          </Link>
        ) : (
          <Link to="/login">
            <ButtonPrimary style={{ maxWidth: '150px' }} arrow={<ArrowNext width="24px" style={{ marginLeft: '10px' }} />}>
              SIGN IN
            </ButtonPrimary>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Banner
