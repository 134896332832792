import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from 'antd'
import './styles.scss'
import ModalCustom from 'components/BaseModal'
import ButtonPrimary from 'widgets/ButtonPrimary'
import CurrencyFormat from 'react-currency-format'
import { isNumber } from 'lodash'
import { orderStake } from 'state/stake/actions'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'
import { addDays, formatDate, roundNumber } from 'helpers'
import { DECIMALS } from 'constants/index'
import { getCurrency } from 'state/wallets/actions'
import FormatAmount from 'components/FormatAmount'

const ModalStaking = ({ visible, dataModal, onSuccess, onCancel, onFinish, ...props }) => {
  const dispatch = useDispatch()
  const { currency } = useSelector((state) => state.wallets)
  const [selectMonth, setSelectMonth] = useState()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [amount, setAmount] = useState('')
  const [accepted, setAccepted] = useState()

  useEffect(() => {
    if (dataModal?.selectMonth) {
      setSelectMonth(dataModal.selectMonth)
    }
  }, [dataModal?.selectMonth])

  const title = dataModal?.currency_stake.code
  const icons = dataModal?.currency_stake.icon
  const termsArr = dataModal?.terms
  const optionMonth = termsArr?.map((o) => ({
    title: o.title || o.title,
    value: o._id,
  }))
  const termsReward = termsArr?.find((o) => o._id === selectMonth)

  const handleCancel = () => {
    setLoading(false)
    onCancel()
    setErrorMess('')
    setAmount('')
  }

  // Amount availabel
  const baseCurrency = useMemo(() => {
    if (title) {
      return currency?.find((item) => item._id === dataModal?.currency_stake?._id)
    }
    return undefined
  }, [currency, dataModal?.currency_stake?._id, title])

  const handleMax = () => {
    if (!baseCurrency) {
      return setErrorMess('Currency is not correct')
    }
    const limitAmount = dataModal.max_stake / 10 ** DECIMALS
    const balanceAmount = baseCurrency?.balance / 10 ** DECIMALS
    if (balanceAmount < limitAmount) {
      setAmount(balanceAmount)
    } else {
      setAmount(limitAmount)
    }
  }

  const handleAmount = (e) => {
    setAmount(e.target.value)
    setLoading(false)
  }

  const handleStake = () => {
    const limitAmount = dataModal.max_stake / 10 ** DECIMALS // limit amount
    const MinimumAmount = dataModal.min_stake / 10 ** DECIMALS // Min_stake
    const balanceAmount = baseCurrency?.balance / 10 ** DECIMALS
    const MaxStakeAmount = dataModal.max_stake / 10 ** DECIMALS

    if (amount < MinimumAmount) {
      return setErrorMess(`Amount Lock must is larger  ${MinimumAmount}  ${title}`)
    }
    if (amount > MaxStakeAmount) {
      return setErrorMess(`Amount Lock must is smaller than ${limitAmount} ${title}`)
    }
    if (amount > balanceAmount) {
      return setErrorMess(`Amount Lock must is smaller than ${balanceAmount} ${title} available`)
    }
    if (!accepted) {
      return setErrorMess(`Make sure you agree to the Diamond Wallet Deposit Service Agreement`)
    }
    if (!isNumber(+amount)) return setErrorMess('Please enter amount')

    const pAmount = amount * 1e18
    setErrorMess(' ')

    setLoading(true)
    dispatch(
      orderStake(
        {
          package_id: dataModal._id,
          term_id: termsReward?._id,
          amount: pAmount,
        },
        (receipt) => {
          onFinish(receipt)
          handleCancel()
          dispatch(getCurrency({ page: 1, pageSize: 100 }))
        },
      ),
    )
  }

  const onChange = (e) => {
    const ischecked = e.target.checked
    setAccepted(ischecked)
    setLoading(false)
  }

  return (
    <ModalCustom className="modal-staking" visible={visible} loading={loading} width={900} onCancel={onCancel} {...props}>
      {dataModal && (
        <div className="modal-staking-content">
          <div className="modal-staking-left">
            <div className="modal-staking-helper">
              Locked staking
              <span className="modal-staking-helper-loo">{dataModal.type}</span>
            </div>
            <div className="modal-staking-coin-logo">
              <img src={icons} alt={title} />
              <div>{title}</div>
            </div>
            <div className="modal-staking-des">{dataModal.description}</div>
            <div className="modal-staking-duration">
              <div className="modal-duration-text">Duration:</div>
              <div className="modal-staking-duration-number">
                {optionMonth?.map((m, index) => (
                  <div
                    key={`duration-${index}`}
                    data-active={(selectMonth === m.value).toString()}
                    role="presentation"
                    onClick={() => setSelectMonth(m.value)}
                  >
                    <span>{m.title}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-staking-input">
              <div className="modal-staking-input-label">
                <span>Lock Amount</span>
                <div className="modal-staking-input-label-suffix">
                  <span>Balance:</span>
                  <span>
                    <FormatAmount value={roundNumber(baseCurrency?.balance, { decimals: DECIMALS })} suffix={` ${title}`} />
                  </span>
                </div>
              </div>
              <div className="modal-staking-input-input">
                <input type="number" placeholder="Please enter the amount" value={amount} onChange={handleAmount} {...props} />
                <span className="token-name">{title}</span>
                <span className="btn-max">
                  {dataModal?.currency_stake.usd_rate !== undefined && (
                    <button type="button" onClick={() => handleMax()}>
                      Max
                    </button>
                  )}
                </span>
              </div>
            </div>
            <div className="amount-limit">
              <div>Min Stake</div>
              <div>
                <CurrencyFormat
                  value={roundNumber(dataModal?.min_stake / 10 ** DECIMALS)}
                  displayType="text"
                  thousandSeparator
                  renderText={(v) => v}
                />
                <span>{title}</span>
              </div>
            </div>
          </div>
          <div className="modal-staking-right">
            <div className="modal-staking-right-title">Summary</div>
            <div className="modal-staking-right-step">
              <div className="step-item">
                <div className="step-item-name">Stake Date</div>
                <div className="step-item-date">{formatDate(new Date().getTime(), 'YYYY-MM-DD')}</div>
              </div>
              <div className="step-item">
                <div className="step-item-name">Value Date</div>
                <div className="step-item-date">{formatDate(new Date().getTime(), 'YYYY-MM-DD')}</div>
              </div>
              <div className="step-item">
                <div className="step-item-name">End Date</div>
                <div className="step-item-date">
                  {formatDate(addDays(new Date().getTime(), termsReward?.day_reward || 0), 'YYYY-MM-DD')}
                </div>
              </div>
            </div>
            {termsReward?.rewards.map((i, index) => (
              <div key={`est-${index}`} className="modal-staking-right-apr">
                <div>{i.currency_reward.title} Est APR: </div>
                <div>{i.apr_reward}%</div>
              </div>
            ))}
            <Checkbox onChange={onChange} className="modal-staking-right-agreement">
              I have read and agree to Zodi Pay Service Agreement
            </Checkbox>
            <p className="error-mess">{errorMess}</p>
            <div className="modal-staking-actions">
              <ButtonPrimary onClick={handleStake} loading={loading}>
                Stake
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )}
    </ModalCustom>
  )
}

export default ModalStaking
