import { all, takeLatest, call, put } from 'redux-saga/effects'
import qs from 'query-string'
import fetchHelper from 'helpers/FetchHelper'
import { ROOT_API_URL } from 'constants/index'
import * as types from './constants'
import * as actions from './actions'

/**
 * Get Order list
 */
function getOrderListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getOrderList({ payload }) {
  try {
    const { data } = yield call(getOrderListFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getOrderListSuccess(data.data))
    }
  } catch (error) {
    console.error('getOrderList', error)
  }
}
/**
 * Get PackageList list
 */
function getPackageListFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/package/list?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getPackageList({ payload }) {
  try {
    const { data } = yield call(getPackageListFromApi, payload)
    if (data.code === 200) {
      yield put(actions.getPackageListSuccess(data.data))
    }
  } catch (error) {
    console.error('getPackageList', error)
  }
}
/**
 * Order stake
 */
function orderStakeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/stake`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* orderStake({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(orderStakeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('orderStake', error)
  }
}

/**
 * Get Order detail
 */
function getOrderDetailFromApi(payload) {
  const qStr = qs.stringify(payload)
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/detail?${qStr}`, {
      method: 'GET',
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* getOrderDetail({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(getOrderDetailFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    console.error('getOrderDetail', error)
  }
}

function orderStakeReportFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/report`, {
      method: 'GET',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* orderStakeReport({ payload }) {
  try {
    const { data } = yield call(orderStakeReportFromApi, payload)

    if (data.code === 200) {
      yield put(actions.orderStakeReportSuccess(data.data))
    }
  } catch (error) {
    console.error('orderStakeReport', error)
  }
}

/**
 * Order stake claim reward
 */
function orderStakeClaimRewardFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/claimReward`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* orderStakeClaimReward({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(orderStakeClaimRewardFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('orderStake', error)
  }
}

// unstake
function requestUnstakeFromApi(payload) {
  return fetchHelper
    .fetch(`${ROOT_API_URL}/order/unstake`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    .then(([resp, status]) => ({
      data: resp,
      status,
    }))
}
function* requestUnstake({ payload, cbs, cbe }) {
  try {
    const { data } = yield call(requestUnstakeFromApi, payload)
    if (data.code === 200) {
      if (cbs) cbs(data.data)
    } else if (cbe) {
      cbe(data)
    }
  } catch (error) {
    if (cbe) cbe(error)
    console.error('requestUnstake', error)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(types.GET_ORDER_LIST, getOrderList),
    takeLatest(types.GET_PACKAGE_LIST, getPackageList),
    takeLatest(types.ORDER_STAKE, orderStake),
    takeLatest(types.GET_ORDER_DETAIL, getOrderDetail),
    takeLatest(types.GET_ORDER_STAKE_REPORT, orderStakeReport),
    takeLatest(types.GET_ORDER_STAKE_CLAIM_REWARD, orderStakeClaimReward),
    takeLatest(types.REQUEST_UNSTAKE, requestUnstake),
  ])
}
