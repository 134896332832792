import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QRcode from 'qrcode.react'
import './styles.scss'
import InputCode from 'components/InputCode'
import ModalCustom from 'components/BaseModal'
import { update2FA, verify2FA } from 'state/auth/actions'
import { getProfile } from 'state/user/actions'
import InputCopy from 'components/InputCopy'

export const MODAlTYPE = {
  CREATE: 'create',
  VERIFY: 'verify',
  UPDATE: 'update',
}
const initialField = 6

/**
 * @param {*} dataModal.code if is required for show QR Code
 * @param {*} modalType type of dispatch
 */

const ModalTurnOn2FA = ({ visible, dataModal, modalType, onCancel, ...props }) => {
  const dispatch = useDispatch()

  const { userInfo } = useSelector((state) => state.user)

  const [code, onChangeCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')

  const handleCancel = () => {
    setErrorMess('')
    setLoading(false)
    onChangeCode('')
    onCancel()
  }

  const handleOk = () => {
    if (code.length < initialField) return setErrorMess('An occurred error, Please try again late.')
    setLoading(true)
    setErrorMess('')

    switch (modalType) {
      case MODAlTYPE.VERIFY:
        dispatch(
          verify2FA(
            {
              token: code,
            },
            () => {
              setLoading(false)
              handleCancel()
              dispatch(getProfile())
            },
          ),
        )
        break
      case MODAlTYPE.UPDATE:
        dispatch(
          update2FA(
            {
              token: code,
              status: userInfo.status_2fa === 2,
            },
            () => {
              setLoading(false)
              handleCancel()
              dispatch(getProfile())
            },
            () => {
              setLoading(false)
              dispatch(getProfile())
            },
          ),
        )
        break
      default:
        setLoading(false)
        dispatch(getProfile())
        handleCancel()
        break
    }
  }

  const renderModalDescription = (type) => {
    switch (type) {
      case MODAlTYPE.VERIFY:
        return 'Get code from your Google Authenticator app on your mobile phone!'
      case MODAlTYPE.UPDATE:
        return 'Get code from your Google Authenticator app on your mobile phone!'
      default:
        return 'Get code from your email'
    }
  }

  return (
    <ModalCustom
      title="Verify Code"
      className="modal-2fa"
      visible={visible}
      loading={loading}
      onOke={handleOk}
      onCancel={handleCancel}
      {...props}
    >
      {dataModal?.data && (
        <div className="canvas-qrcode">
          <QRcode size={200} value={dataModal.data?.toString()} />
        </div>
      )}

      {dataModal?.code && <InputCopy value={dataModal.code} />}
      <div className="text-note">{renderModalDescription(modalType)}</div>
      <InputCode value={code} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
    </ModalCustom>
  )
}

export default ModalTurnOn2FA
