import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import './styles.scss'
import { Col, Row } from 'antd'
import InputPanel from '../InputPanel'
import { useDerivedExchangeInfo, useExchangeState } from 'state/exchange/hooks'
import { Field } from 'state/exchange/const'
import { ArrowUpDownIcon } from 'widgets/Svg'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { useSwapActionHandlers } from 'state/exchange/useSwapActionHandlers'
import ModalExchange from '../ModalExchange'

const CardExchange = ({ setParamsTxs }) => {
  const { currency } = useSelector((state) => state.wallets)

  const optionsFromCurrency = useMemo(() => {
    if (currency) {
      return currency
        .filter((o) => ['USDT', 'USDO'].includes(o.code))
        .map((item) => ({
          label: item.title,
          value: item.code,
          type: item.type,
          currency_code: item.code,
          image: item.icon,
          price: 2,
        }))
    }
    return undefined
  }, [currency])

  const [errorMess, setErrorMess] = useState('')
  const [modalExchange, setModalExchange] = useState({ open: false, dataModal: null })

  const { onSwitchTokens, onCurrencySelection, onUserInput } = useSwapActionHandlers()

  // swap state & price data
  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useExchangeState()
  const dependentField = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  // here calculate amountOutput
  const { currencies, outputAmount, inputError } = useDerivedExchangeInfo(typedValue, inputCurrencyId, outputCurrencyId)

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: outputAmount ?? '',
  }

  // handle exchange now
  const handleExchange = useCallback(() => {
    if (!inputCurrencyId || !outputCurrencyId) {
      setErrorMess('Please select a token')
      return
    }
    if (!typedValue) {
      setErrorMess('Please enter amount')
      return
    }
    if (!outputAmount) {
      setErrorMess('Insuffix amount')
      return
    }

    setErrorMess('')
    setModalExchange({
      open: true,
      dataModal: {
        inputCurrencyId,
        outputCurrencyId,
        typedValue,
        outputAmount,
      },
    })
  }, [inputCurrencyId, outputCurrencyId, typedValue, outputAmount])

  // console.log(inputError)

  // user select token
  const handleInputSelect = useCallback(
    (currencyInput) => {
      onCurrencySelection(Field.INPUT, JSON.parse(currencyInput))
    },
    [onCurrencySelection],
  )
  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, JSON.parse(currencyOutput))
    },
    [onCurrencySelection],
  )

  // user input, output amount
  const handleTypeInput = useCallback(
    (value) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  /* set default select if option is valid */
  useEffect(() => {
    if (inputCurrencyId || inputCurrencyId) return // break out if input is select
    if (optionsFromCurrency) {
      const inputSelect = optionsFromCurrency[0]
      const outputSelect = optionsFromCurrency[1]
      handleInputSelect(inputSelect ? JSON.stringify(inputSelect) : undefined)
      handleOutputSelect(outputSelect ? JSON.stringify(outputSelect) : undefined)
    }
  }, [handleInputSelect, handleOutputSelect, inputCurrencyId, optionsFromCurrency])

  return (
    <div className="card-exchange">
      <Row
        gutter={[
          { xs: 4, md: 24 },
          { xs: 4, md: 24 },
        ]}
      >
        <Col span={24}>
          <InputPanel
            title="From currency"
            value={formattedAmounts[Field.INPUT]}
            isError={!!errorMess}
            walletsList={currency}
            optionsSelect={optionsFromCurrency}
            currency={currencies[Field.INPUT]}
            otherCurrency={currencies[Field.OUTPUT]}
            onUserInput={handleTypeInput}
            onCurrencySelect={handleInputSelect}
          />
        </Col>
        <Col span={24}>
          <div className="exchange-swap-icon">
            <ArrowUpDownIcon className="icon-down" onClick={onSwitchTokens} />
          </div>
        </Col>
        <Col span={24}>
          <InputPanel
            title="To currency"
            value={formattedAmounts[Field.OUTPUT]}
            isError={!!errorMess}
            walletsList={currency}
            optionsSelect={optionsFromCurrency}
            currency={currencies[Field.OUTPUT]}
            otherCurrency={currencies[Field.INPUT]}
            onUserInput={handleTypeOutput}
            onCurrencySelect={handleOutputSelect}
          />
        </Col>
        <Col span={24}>
          <div className="exchange-swap-action">
            <ButtonPrimary onClick={handleExchange}>{inputError || 'Exchange'}</ButtonPrimary>
          </div>
        </Col>
      </Row>
      <ModalExchange
        visible={modalExchange.open}
        dataModal={modalExchange.dataModal}
        setModal={setModalExchange}
        setParamsTxs={setParamsTxs}
      />
    </div>
  )
}

export default CardExchange
