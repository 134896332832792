import React from 'react'
import QRCode from 'qrcode.react'
import './styles.scss'
import ModalCustom from 'components/BaseModal'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'

const ModalScan = ({ visible, value, close }) => (
  <ModalCustom
    title="Scan to deposit"
    className="modal-qrcode-deposit"
    centered
    visible={Boolean(visible)}
    width={720}
    onCancel={() => close(false)}
  >
    <div className="modal-deposit-qs-code">
      <QRCode size={200} value={value} />
    </div>
    <p className="title-modal">Scan to deposit</p>
    <p>Powered by Zodi Pay</p>

    <div className="modal-scan-action">
      <ButtonSecondary style={{ maxWidth: '150px', marginRight: '24px' }} onClick={() => close(false)}>
        Share
      </ButtonSecondary>
      <ButtonPrimary style={{ maxWidth: '150px' }} onClick={() => close(false)}>
        Save
      </ButtonPrimary>
    </div>

    <div className="modal-scan-action-bg" />
  </ModalCustom>
)

export default ModalScan
