import React from 'react'
import OtpInput from 'react-otp-input'
import './styles.scss'

const InputCode = ({ initialField, handleChange, ...props }) => (
  <div className="input-code-container">
    <OtpInput
      className="input-code-item"
      errorStyle={{ color: 'red', borderColor: 'red' }}
      numInputs={initialField}
      separator={<div style={{ width: '10%' }} />}
      shouldAutoFocus
      {...props}
    />
  </div>
)

InputCode.defaultProps = {
  initialField: 6,
}

export default InputCode
