import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox, Form, Row, Col } from 'antd'
import './styles.scss'
import { ArrowNext } from 'widgets/Svg'
import ControlInputSelect from 'components/ControlInputSelect'
import Input from 'widgets/Input'
import FormItem from 'widgets/FormItem'
import AuthWrapper from 'modules/auth/components/AuthWrapper'
import AuthCard from 'modules/auth/components/AuthCard'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { postSignup } from 'state/auth/actions'
import { getListCountry } from 'state/app/actions'
import VerifyActiveAccountCode from 'modules/auth/components/VerifyActiveAccountCode'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'

const Signup = ({ history }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const { listCountry } = useSelector((state) => state.app)

  const [view, setView] = useState(history.location.state?.view || 0)
  const [email, setEmail] = useState(history.location.state?.email || null)
  const [inputCountry, setInputCountry] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getListCountry())
  }, [dispatch])

  const dataSelectCountry = useMemo(() => {
    if (listCountry) {
      return listCountry.map((item) => ({
        ...item,
        label: item.title,
        value: item.code,
      }))
    }
    return []
  }, [listCountry])

  const onFormSubmit = () => {
    setLoading(true)
    form.validateFields().then((values) => {
      if (!values.password || !values.confirm) return
      if (values.password !== values.confirm) return

      delete values.confirm
      const formData = {
        ...values,
        country: inputCountry,
      }

      setEmail(values.email)

      dispatch(
        postSignup(
          {
            ...formData,
          },
          () => {
            setView(1)
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
    })
  }

  const onBack = useCallback(() => setView({ view: 0, data: null }), [])

  return (
    <AuthWrapper>
      <div className="wrapper-form-signup">
        {view === 0 ? (
          <AuthCard className="form-signup fadeIn">
            <Form layout="vertical" form={form} onFinish={onFormSubmit}>
              <FormItem
                label={<span>Email</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter email field',
                  },
                ]}
              >
                <Input placeholder="example@gmail.com" />
              </FormItem>
              <FormItem
                label={<span>Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter password field',
                  },
                  {
                    min: 10,
                    message: `The 'password' field length must be greater than or equal to 10 characters long.`,
                  },
                ]}
              >
                <Input placeholder="**********" type="password" />
              </FormItem>
              <FormItem
                label={<span>Confirm Password</span>}
                name="confirm"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'))
                    },
                  }),
                ]}
              >
                <Input placeholder="**********" type="password" />
              </FormItem>
              <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <FormItem
                    label={<span>First name</span>}
                    name="fistname"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter fistname field',
                      },
                    ]}
                  >
                    <Input placeholder="Fist name" type="text" />
                  </FormItem>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <FormItem
                    label={<span>Last name</span>}
                    name="lastname"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter lastname field',
                      },
                    ]}
                  >
                    <Input placeholder="Last name" type="text" />
                  </FormItem>
                </Col>
              </Row>
              <FormItem
                label={<span>Phone number</span>}
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter phone number field',
                  },
                ]}
              >
                <Input placeholder="Phone number" type="text" />
              </FormItem>
              <FormItem label={<span>Country</span>}>
                <ControlInputSelect
                  style={{ width: '100%' }}
                  placeholder="Country"
                  dataSelect={dataSelectCountry}
                  onChange={(v) => setInputCountry(JSON.parse(v).value)}
                />
              </FormItem>

              <FormItem
                className="form-item-checkbox"
                name="agreement"
                formType="checkbox"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'))),
                  },
                ]}
              >
                <Checkbox>
                  I have read and agree to <a href="/#">User Agreement and Privacy Policy</a>
                </Checkbox>
              </FormItem>
              <FormItem>
                <ButtonPrimary
                  type="submit"
                  className="form-action"
                  arrow={<ArrowNext width="24px" style={{ marginLeft: '10px' }} />}
                  style={{ maxWidth: '150px' }}
                  loading={loading}
                >
                  SIGN UP
                </ButtonPrimary>
              </FormItem>
            </Form>
            <div className="form-links">
              <span>Already have Account.</span>
              <Link to="/login">Sign In</Link>
            </div>
          </AuthCard>
        ) : (
          <VerifyActiveAccountCode email={email} token={view?.data?.token} onBack={onBack} />
        )}
      </div>
    </AuthWrapper>
  )
}

export default Signup
