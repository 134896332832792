import React, { useEffect, useState } from 'react'
import './style.scss'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { formatBigNumber, getDateToDate, roundNumber } from 'helpers'
import FormatAmount from 'components/FormatAmount'

export const LIVE_STATUS = {
  UPCOMING: 'UPCOMING',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
}

const CardStakingItem = ({ onStakeNow, item, txParamsStakingList, updateStakingList }) => {
  const title = item?.currency_stake.code
  const { icon } = item.currency_stake
  const termsArr = item.terms

  const optionMonth = termsArr.map((o) => ({
    label: o.month_reward || o.day_reward,
    value: o._id,
  }))

  const [selectMonth, setSelectMonth] = useState(optionMonth[0]?.value)

  const termsReward = termsArr.find((o) => o._id === selectMonth)
  const totalStaked = termsArr?.reduce((total, curr) => {
    const tt = total + formatBigNumber(curr.totalStaked)
    return tt
  }, 0)

  const [countdownTime, setCountdownTime] = useState({
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    weekdays: 0,
  })
  useEffect(() => {
    function loop() {
      const currentTime = new Date().getTime()
      const startDate = item.start_date * 1000
      const objDate = getDateToDate(currentTime, startDate)
      setCountdownTime(objDate)
      if (currentTime >= startDate) {
        updateStakingList()
      }
    }

    if (txParamsStakingList.live_status === LIVE_STATUS.UPCOMING) {
      const timer = setInterval(() => {
        loop()
      }, 1000)
      return () => clearInterval(timer, loop)
    }
  }, [item, txParamsStakingList, updateStakingList])

  return (
    <div className="staking-card-item">
      <div className="staking-card-item-header">
        <img src={icon} alt={title} />
        <div className="staking-helper-title">{title}</div>
        <span className="staking-helper-loo">{item.type}</span>
      </div>
      <div className="staking-card-item-des">{item.description}</div>
      <div className="staking-card-item-dup-logo">
        <div>
          <img src={icon} alt="" />
        </div>
        <div>
          <img src="/images/coins/zodi2.png" alt="" />
        </div>
      </div>

      <div className="stake-volume">
        <div>Staked volume</div>
        <div>
          <FormatAmount value={roundNumber(totalStaked)} suffix={` ${title}`} />
        </div>
      </div>
      <div className="duration-wrapper">
        <div className="staking-card-item-duration">
          <div className="staking-card-item-duration-text">Duration (D) :</div>
          <div className="staking-card-item-duration-number">
            {optionMonth?.map((m, index) => (
              <div
                key={`duration-${index}`}
                data-active={(selectMonth === m.value).toString()}
                role="presentation"
                onClick={() => setSelectMonth(m.value)}
              >
                <span>{m.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="wards-list">
          {termsReward?.rewards.map((i, index) => (
            <div key={`list-${index}`} className="staking-card-item-note">
              <span>{i.currency_reward.title} Est APR: </span>
              <span>{i.apr_reward}%</span>
            </div>
          ))}
        </div>
      </div>
      <div className="staking-card-item-actions">
        {txParamsStakingList.live_status === LIVE_STATUS.LIVE && (
          <ButtonPrimary onClick={() => onStakeNow({ ...item, selectMonth })}>Stake Now</ButtonPrimary>
        )}
        {txParamsStakingList.live_status === LIVE_STATUS.UPCOMING && (
          <ButtonPrimary disabled>
            {`${(countdownTime.weekdays * 7 + countdownTime.days)
              .toString()
              .padStart(2, '0')}:${countdownTime.hours.toString().padStart(2, '0')}:${countdownTime.minutes
              .toString()
              .padStart(2, '0')}:${countdownTime.seconds.toString().padStart(2, '0')}`}
          </ButtonPrimary>
        )}
        {txParamsStakingList.live_status === LIVE_STATUS.ENDED && <ButtonPrimary disabled>Ended</ButtonPrimary>}
      </div>
    </div>
  )
}

export default CardStakingItem
