import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ButtonPrimary from 'widgets/ButtonPrimary'
import ButtonSecondary from 'widgets/ButtonSecondary'
import './styles.scss'
import { Form } from 'antd'
import FormItem from 'widgets/FormItem'
import AuthCard from '../AuthCard'
import { forgotPassword, requestForgotPassword } from 'state/auth/actions'
import InputCode from 'components/InputCode'
import { useHistory } from 'react-router-dom'
import Input from 'widgets/Input'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'

const defaultTimeCountDown = 50

const VerifyResetPasswordCode = ({
  email,
  initialField,
  token,
  onFinish,
  onBack,
  children,
  title,
  timeResend,
  setTimeResend,
  ...props
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')
  const [timeCountDown, setTimeCountDown] = useState(defaultTimeCountDown)

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (codeValue.length < initialField) {
        setErrorMess(`Please enter ${initialField} characters from email of you.`)
      } else {
        setErrorMess('')
        setLoading(true)
        dispatch(
          forgotPassword(
            {
              ...values,
              email: `${email}`,
              code: codeValue,
            },
            () => {
              history.push('/login')
              setLoading(false)
            },
            () => {
              setLoading(false)
            },
          ),
        )
      }
    })
  }

  const onChangeCode = (value) => {
    if (value.length >= initialField) {
      setErrorMess('')
    }
    setCodeValue(value)
  }

  const resendCode = () => {
    dispatch(
      requestForgotPassword(
        {
          email: `${email}`,
        },
        () => {
          if (setTimeResend) {
            setTimeResend(defaultTimeCountDown)
            setTimeCountDown(defaultTimeCountDown)
          } else {
            setTimeCountDown(defaultTimeCountDown)
          }
        },
      ),
    )
  }

  useEffect(() => {
    const countDownInterval = setInterval(() => {
      setTimeCountDown((prev) => {
        if (prev > 0) {
          const down = prev - 1
          return down
        }
        return 0
      })
    }, 1000)

    return () => {
      clearInterval(countDownInterval)
    }
  }, [])

  useEffect(() => {
    if (timeResend && timeResend > 0) {
      setTimeCountDown(timeResend)
    }
  }, [timeResend])

  return (
    <AuthCard className="verify-code fadeIn" {...props}>
      <h2 className="verify-title">{title ? `${title}` : 'Reset password'}</h2>

      <Form layout="vertical" form={form}>
        <FormItem
          label={<span>Enter your new password</span>}
          name="new_password"
          rules={[
            {
              required: true,
              message: 'Please enter password field',
            },
          ]}
        >
          <Input placeholder="************" type="password" />
        </FormItem>

        <FormItem
          label={<span>Confirm Password</span>}
          name="confirm"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            }),
          ]}
        >
          <Input placeholder="**********" type="password" />
        </FormItem>

        <div className="verify-input">
          <p>Verification code</p>
          <InputCode value={codeValue} initialField={initialField} hasErrored={Boolean(errorMess)} onChange={onChangeCode} />
        </div>
        <div className="verify-resend">
          <div style={{ opacity: timeCountDown > 0 ? '1' : '0' }}>Resend in {timeCountDown}</div>
          {timeCountDown <= 0 && (
            <a onClick={() => resendCode()} role="presentation">
              Resend
            </a>
          )}
        </div>

        <div className="verify-error">{errorMess}</div>
        <div className="verify-action">
          <ButtonPrimary loading={loading} onClick={onSubmit}>
            Done
          </ButtonPrimary>
          {!title ? <ButtonSecondary onClick={onBack}>Back</ButtonSecondary> : ''}
        </div>
      </Form>
    </AuthCard>
  )
}

VerifyResetPasswordCode.defaultProps = {
  onFinish: () => null,
  onBack: () => null,
}

export default React.memo(VerifyResetPasswordCode)
