import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import InputCode from 'components/InputCode'
import ButtonPrimary from 'widgets/ButtonPrimary'
import { ExclamationIcon } from 'widgets/Svg'
import { verifyEmail } from 'state/auth/actions'
import AuthCard from '../AuthCard'
import { executeRecaptcha } from 'helpers/GoogleRecaptcha'

const VerifyAuthenCode = ({ initialField, token, onFinish, children, ...props }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMess, setErrorMess] = useState('')
  const [codeValue, setCodeValue] = useState('')

  const onSubmit = () => {
    const code = Object.values(codeValue).filter((o) => o)
    if (code.length < initialField) {
      setErrorMess(`Please enter ${initialField} characters Google Authenticator on the Mobile App`)
    } else {
      setErrorMess('')
      setLoading(true)
      dispatch(
        verifyEmail(
          {
            token,
            code: code.join(''),
            os: window.navigator.platform,
            device_id: window.navigator.appVersion,
          },
          (receipt) => {
            onFinish({ code: codeValue, type: 'is_email', ...receipt })
            setLoading(false)
          },
          () => {
            setLoading(false)
          },
        ),
      )
      // dispatch(
      //   verifyEmail(
      //     {
      //       token: code.join(''),
      //       code: codeValue,
      //       os: window.navigator.platform,
      //       device_id: window.navigator.appVersion,
      //     },
      //     (receipt) => {
      //       onFinish({ code: codeValue, type: 'is_email', ...receipt })
      //       setLoading(false)
      //     },
      //     () => {
      //       setLoading(false)
      //     },
      //   ),
      // )
    }
  }

  return (
    <AuthCard className="authen-code fadeIn" {...props}>
      <h2 className="authen-title">Google Authenticator</h2>
      <p className="authen-des">Enter {initialField} characters or past copied text</p>
      <div className="authen-input">
        <InputCode initialField={initialField} value={codeValue} onChange={setCodeValue} />
      </div>
      <div className="authen-error">{errorMess}</div>
      <div className="authen-action">
        <ButtonPrimary size="larger" loading={loading} onClick={onSubmit}>
          Done
        </ButtonPrimary>
      </div>
      <div className="authen-exclamation">
        <ExclamationIcon />
        <p>The Zodi Pay/Google Authenticator app protects your withdrawals and Zodi Pay account</p>
      </div>
    </AuthCard>
  )
}

VerifyAuthenCode.defaultProps = {
  initialField: 6,
  onFinish: () => null,
}

export default VerifyAuthenCode
