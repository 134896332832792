import { useEffect, useState } from 'react'
import * as qs from 'query-string'
import { ROOT_API_URL } from 'constants/index'
import fetchHelper from 'helpers/FetchHelper'

const useDetectAddress = (address) => {
  // detect address is exit in system
  const [isInSystem, setIsAddressInSystem] = useState(false)
  useEffect(() => {
    async function init() {
      const str = qs.stringify({
        address,
      })
      fetchHelper
        .fetch(`${ROOT_API_URL}/transaction/checkAddress?${str}`, {
          method: 'GET',
        })
        .then(([result, status]) => {
          if (status === 200) {
            setIsAddressInSystem(result?.data?.is_internal || false)
          }
        })
        .catch((error) => {
          console.log('check address fail', error)
        })
    }
    if (address) {
      init()
    } else {
      setIsAddressInSystem(false)
    }
  }, [address])

  return { isInSystem }
}

export default useDetectAddress
