import React from 'react'
import { DatePicker } from 'antd'
import './styles.scss'

const AntDatePicker = ({ label, onChange, ...props }) => (
  <div className="Datepicker-custom">
    <p>{label}</p>
    <DatePicker autoComplete="true" allowClear={false} onChange={onChange} {...props} />
    {/* <img
      style={{ cursor: 'pointer' }}
      onClick={() => setPickerOpen((prev) => !prev)}
      src="/images/icons/icon-calendar.png"
      role="presentation"
      alt=""
    /> */}
  </div>
)

export default AntDatePicker
