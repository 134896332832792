import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import { List } from 'antd'
import './styles.scss'
import { DECIMALS } from 'constants/index'
import TableRecentTransactionDesktop from '../TableRecentTransactionDesktop'
import ItemRecentTransaction from '../itemRecentTransaction'
import ModalViewDetails from '../ModalViewDetails'
import Nodata from 'components/Nodata'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from 'widgets/Loading/Loading'
import { roundNumber, formatDate, countDateToDate } from 'helpers'
import ButtonPrimary from 'widgets/ButtonPrimary'
import useIsMobile from 'hooks/useIsMobile'

const responsiveList = { gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 3 }

const RecentTransaction = ({ rows, txParams, setTxParams, orderList, setModalVerifyCode, onUnstakeSuccess }) => {
  const { currency } = useSelector((state) => state.wallets)
  const isMobile = useIsMobile()

  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })
  const [loading, setLoading] = useState(false)

  const handleOrderDetail = (record) => {
    setViewDetails({ toggle: true, dataModal: record })
  }

  const loadMoreData = () => {
    if (loading) {
      return
    }

    setTxParams((prev) => ({
      ...prev,
      pageSize: prev.pageSize + 6,
    }))
  }
  useEffect(() => {
    loadMoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = useMemo(
    () => [
      {
        title: 'No',
        dataIndex: '',
        width: 30,
        render: (text, _, index) => <div className="recent-transaction-table-index">{index + 1}</div>,
      },
      {
        title: 'Currency',
        dataIndex: 'currency_stake_id',
        render: (text) => {
          const fCurrency = currency?.find((curr) => curr._id === text)

          return (
            <div className="recent-transaction-table-currency">
              <span>
                <img src={fCurrency.icon} alt="icon" />
              </span>

              <span>{fCurrency?.code || ''}</span>
            </div>
          )
        },
      },
      {
        title: 'Amount',
        dataIndex: 'amount_stake',
        render: (text) => (
          <div className="recent-transaction-table-amount">
            <CurrencyFormat
              value={roundNumber(text / 10 ** DECIMALS, 6)}
              displayType="text"
              thousandSeparator
              renderText={(v) => v}
            />
          </div>
        ),
      },
      // {
      //   title: 'Earned',
      //   dataIndex: 'earned',
      //   render: (text) => (
      //     <div className="recent-transaction-table-earned">
      //       <CurrencyFormat
      //         value={roundNumber(text || 0, { scale: 6, decimals: DECIMALS })}
      //         suffix={` OPV`}
      //         displayType="text"
      //         thousandSeparator
      //         renderText={(v) => v}
      //       />
      //     </div>
      //   ),
      // },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => (
          <div className="recent-transaction-table-status" data-status={text}>
            {text}
          </div>
        ),
      },
      {
        title: 'Lock Period',
        dataIndex: 'subscription_date',
        render: (text, record) => (
          <div className="recent-transaction-table-period">
            {countDateToDate(record.subscription_date * 1000, record.redemption_date * 1000)}
          </div>
        ),
      },
      {
        title: 'Start',
        dataIndex: 'subscription_date',
        render: (text) => <div className="recent-transaction-table-time">{formatDate(text * 1000, 'YYYY/MM/DD')}</div>,
      },
      {
        title: 'End',
        dataIndex: 'redemption_date',
        render: (text) => <div className="recent-transaction-table-time">{formatDate(text * 1000, 'YYYY/MM/DD')}</div>,
      },
      {
        title: '',
        dataIndex: 'status',
        render: (text, record) => {
          if (text !== 'HOLDING') {
            return <></>
          }
          return (
            <ButtonPrimary className="recent-transaction-table-action" onClick={() => handleOrderDetail(record)}>
              UNSTAKE
            </ButtonPrimary>
          )
        },
      },
    ],
    [currency],
  )

  return (
    <>
      <div className="history-box-recent-transaction">
        {isMobile ? (
          <div style={{ minHeight: '100px' }}>
            {rows?.length > 0 ? (
              <InfiniteScroll
                className="infinite-scroll"
                style={{ overflow: 'hidden' }}
                dataLength={orderList?.rows?.length || 0}
                next={loadMoreData}
                hasMore={orderList?.rows?.length < orderList?.total}
                loader={
                  <List
                    dataSource={[{}]}
                    grid={responsiveList}
                    renderItem={(_, index) => (
                      <List.Item key={`list-${index}`}>
                        <div className="loading-list-stake">
                          <div>
                            Loading
                            <Loading />
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                }
                scrollableTarget="scrollableDiv"
              >
                <List
                  dataSource={rows || []}
                  grid={responsiveList}
                  renderItem={(item, index) => (
                    <List.Item key={`list-${index}`}>
                      <ItemRecentTransaction
                        data={item}
                        index={index}
                        key={index}
                        setViewDetails={setViewDetails}
                        setModalVerifyCode={setModalVerifyCode}
                      />
                    </List.Item>
                  )}
                  locale={{
                    emptyText: orderList?.total === 0 ? <div>Empty</div> : <></>,
                  }}
                />
              </InfiniteScroll>
            ) : (
              <Nodata />
            )}
          </div>
        ) : (
          <TableRecentTransactionDesktop
            txParams={txParams}
            orderList={orderList}
            setTxParams={setTxParams}
            scroll={{ x: 600 }}
            columns={columns}
            dataSource={rows || []}
            rowKey={(record) => record._id}
          />
        )}
      </div>

      <ModalViewDetails
        visible={viewDetails.toggle}
        dataModal={viewDetails?.dataModal}
        onCancel={setViewDetails}
        onUnstakeSuccess={onUnstakeSuccess}
      />
    </>
  )
}

export default RecentTransaction
