import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { linksPrivate, linksPublic } from 'components/Menu/config'
import './style.scss'
import PrivateHeader from './components/PrivateHeader'
import { ConnectIcon } from 'widgets/Svg'
import useIsMobile from 'hooks/useIsMobile'
import PrivateFooter from './components/PrivateFooter'
import NavigationMobile from 'components/NavigationMobile'

const PrivateLayout = ({ isLogin, children }) => {
  const location = useLocation()
  const isMobile = useIsMobile()
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => {
    document.querySelector('body').style.overflow = showMenu ? 'unset' : 'hidden'
    setShowMenu((prev) => !prev)
  }

  return (
    <div className="private-layout-container">
      <div className="panel-container">
        <div className="panel-body">
          <div className="panel-top">
            <Link to="/">
              <div className="panel-logo">
                <img src="/images/logo-text.png" title="Zodi Pay" alt="Zodi Pay" />
              </div>
            </Link>
            <ul className="panel-link">
              {linksPrivate.map((item, index) => {
                const isHttp = item?.href?.startsWith('http')
                const Tag = isHttp ? 'a' : Link
                const propsLink = isHttp ? { href: item.href, target: item.target } : { to: item.href }
                const Icon = item.icon
                return (
                  <li key={`panel-mb-${index}`} className={location.pathname === item.href ? 'active' : ''}>
                    <Tag {...propsLink} rel="noreferrer">
                      {item.icon && <Icon />}
                      {item.label}
                    </Tag>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="panel-footer">
            <h5>Customer Support</h5>
            <p>Ask your inquiries , inform us of your requests and important issues , Our support team is available 24/7</p>
            <a className="connect-now" href="https://beurl.app/zodi-telegram-group" target="_blank" rel="noreferrer">
              <button type="button">
                <ConnectIcon />
                Connect Now
              </button>
            </a>
            <a className="term-privacy" href="/#" target="_blank">
              Terms & Services Privacy Policy
            </a>
            <div className="version">Version Beta 0.1</div>
          </div>
        </div>
      </div>
      <div className="private-content" style={{ overflow: showMenu ? 'hidden' : 'auto' }}>
        <PrivateHeader links={linksPublic} isMobile={isMobile} isLogin={isLogin} showMenu={showMenu} toggleMenu={toggleMenu} />
        {children}
        <PrivateFooter links={linksPublic} location={location} isLogin={isLogin} />
      </div>
      {isMobile && <NavigationMobile />}
    </div>
  )
}

export default PrivateLayout
