import React, { useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import './styles.scss'
import { getBscScanLink } from 'utils'
import { DECIMALS } from 'constants/index'
import TableRecentTransaction from '../tableRecentTransaction'
import ItemRecentTransaction from '../itemRecentTransaction'
import ModalViewDetails from '../ModalViewDetails'
import Nodata from 'components/Nodata'
import { roundNumber, formatCode, formatDate } from 'helpers'

const RecentTransaction = ({ txParams, rows, total, setTxParams, setModalVerifyCode }) => {
  const [viewDetails, setViewDetails] = useState({ toggle: false, dataModal: null })
  const columns = useMemo(
    () => [
      {
        title: 'Action',
        dataIndex: 'action',
        render: (text) => <p className="recent-transaction-table-action">{text}</p>,
      },
      {
        title: 'Network',
        dataIndex: 'chain',
        render: (text) => <p className="recent-transaction-table-network">{text}</p>,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        render: (text) => (
          <p className="recent-transaction-table-amount">
            <CurrencyFormat
              value={roundNumber(text / 10 ** DECIMALS, 6)}
              displayType="text"
              thousandSeparator
              renderText={(v) => v}
            />
          </p>
        ),
      },
      {
        title: 'Token',
        dataIndex: 'currency',
        render: (text) => <p className="recent-transaction-table-currency">{text}</p>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => (
          <div className="recent-transaction-table-status">
            <p data-status={text}>{text}</p>
          </div>
        ),
      },
      {
        title: 'Fee',
        dataIndex: 'fee',
        render: (text) => <p className="recent-transaction-table-currency">{text / 10 ** DECIMALS}</p>,
      },
      {
        title: 'Txh',
        dataIndex: 'txhash',
        render: (text, record) => {
          if (record.status === 'COMPLETED') {
            return (
              <a
                className="recent-transaction-table-amount"
                href={getBscScanLink(text, 'transaction', record.chain)}
                target="_blank"
                rel="noreferrer"
              >
                {formatCode(text, 5, 5)}
              </a>
            )
          }

          return record.status
        },
      },
      {
        title: '',
        key: 'operation',
        width: 10,
        render: (_, record) => {
          if (record.status !== 'CREATED') {
            return (
              <a
                className="recent-transaction-table-view"
                role="presentation"
                onClick={() => setViewDetails({ toggle: true, dataModal: record })}
              >
                View
              </a>
            )
          }
          return (
            <a className="recent-transaction-table-view" role="presentation" onClick={() => setModalVerifyCode(record)}>
              Verify
            </a>
          )
        },
      },
      {
        title: 'Time',
        dataIndex: 'createdAt',
        render: (text) => <p className="recent-transaction-table-time">{formatDate(text * 1000)}</p>,
      },
    ],
    [setModalVerifyCode],
  )

  return (
    <>
      <div className="box-recent-transaction">
        <p className="recent-transaction-title">Recent Transaction</p>
        {window.innerWidth <= 991 ? (
          <div style={{ minHeight: '100px' }}>
            {rows?.length > 0 ? (
              rows?.map((item) => (
                <ItemRecentTransaction
                  data={item}
                  key={item._id}
                  setViewDetails={setViewDetails}
                  setModalVerifyCode={setModalVerifyCode}
                />
              ))
            ) : (
              <Nodata />
            )}
          </div>
        ) : (
          <TableRecentTransaction
            columns={columns}
            dataSource={rows || []}
            rowKey={(record) => record._id}
            pagination={{
              total,
              current: txParams.page,
              onChange: (page, limit) => {
                setTxParams((prev) => ({
                  ...prev,
                  page,
                  pageSize: limit,
                }))
              },
            }}
          />
        )}
      </div>

      <ModalViewDetails visible={viewDetails.toggle} dataModal={viewDetails.dataModal} close={setViewDetails} />
    </>
  )
}

export default RecentTransaction
